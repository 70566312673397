@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.ecran_campagne_ajouter_compte {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_ajouter_compte .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_ajouter_compte .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_ajouter_compte .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_ajouter_compte .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_ajouter_compte .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_ajouter_compte .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_ajouter_compte .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_ajouter_compte .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_ajouter_compte .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_ajouter_compte .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_ajouter_compte .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content {
  width: 68%;
  padding: 50px 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #000000;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 60px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok img {
  margin: 0 20px;
  border: 1px solid transparent;
  padding: 5px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok img.selected {
  border: 1px solid rgba(232, 62, 140, 0.5);
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok img.selectedMeta {
  border: 1px solid rgba(255, 0, 0, 0.5);
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .navigation button {
  margin: 0 50px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .navigation button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  padding: 10px 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
  border: none;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .navigation button.outline {
  background-color: transparent;
  border: 1px solid #e83e8c;
  color: #e83e8c;
  padding: 10px 20px;
}/*# sourceMappingURL=style.css.map */