@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.ecran_configuration_monitoring {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_configuration_monitoring .right_content {
  transition: all ease 0.3s;
}
.ecran_configuration_monitoring .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_configuration_monitoring .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_configuration_monitoring .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main {
  padding: 20px 40px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: flex-end;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row_select_account {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row_select_account select {
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row_select_account p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  padding-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row button {
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  padding: 7px 40px;
  border: none;
  border-radius: 20px;
  background-color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group {
  margin-bottom: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group .css-574lq2-MuiFormControl-root {
  margin-top: 0;
  margin-left: 0;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: block;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  margin-right: 30px;
  overflow: hidden;
  border-radius: 5px;
  width: 75%;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .dfe button {
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  padding: 10px 40px;
  border: none;
  border-radius: 25px;
  background-color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  margin-bottom: 30px;
  background: #ffffff;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring .actions {
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring .actions button {
  background-color: #e83e8c;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring .actions button.outline {
  background-color: transparent;
  border: 1px solid #e83e8c;
  color: #e83e8c;
  margin-right: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application {
  padding: 20px 50px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .search_list_campaigns {
  display: flex;
  align-items: flex-end;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .search_list_campaigns .MuiAutocomplete-root {
  flex: 1;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .search_list_campaigns i {
  margin-right: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .app_subtitle {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .app_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.7;
  margin-bottom: 10px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .left {
  display: flex;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add input {
  margin-left: 10px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .right {
  display: flex;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .right h4 {
  margin-right: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .right select {
  min-width: 300px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  table-layout: fixed;
  position: relative;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table .add_btn_table {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #342f32;
  border: 1px solid #342f32;
  background-color: transparent;
  padding: 2px 10px;
  border-radius: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr td:first-child {
  padding-left: 50px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr td:last-child {
  padding-left: 60px;
  padding-right: 60px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody select {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 5px;
  outline: none;
  color: #000;
  background-color: #fff;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody select option {
  color: #000;
  font-weight: bold;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  position: sticky;
  top: 80px;
  left: 0;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box {
  padding: 20px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: #fff6fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box .choix_strategie_title,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box .historique_title {
  margin: 15px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}

/*# sourceMappingURL=style.css.map */
