@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.ecran_priorisation_evenments {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_priorisation_evenments .right_content {
  transition: all ease 0.3s;
}
.ecran_priorisation_evenments .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_priorisation_evenments .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_priorisation_evenments .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_priorisation_evenments .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_priorisation_evenments .right_content_main {
  padding: 20px 40px;
}
.ecran_priorisation_evenments .right_content_main .main__content .main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin: 50px 0;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header .left {
  display: flex;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header .left p {
  margin-right: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header .left select {
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 5px 20px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head {
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  background: rgba(232, 62, 140, 0.03);
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
  position: relative;
  display: flex;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p:last-child {
  display: flex;
  justify-content: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
  background-color: #e83e8c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: #fff;
  font-size: 10px;
  font-family: "Rubik";
  position: relative;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span:hover::after {
  visibility: visible;
  opacity: 1;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span.priority_defaut::after {
  content: "priority_defaut";
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span.event_defaut::after {
  content: "event_defaut";
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span::after {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e83e8c;
  border-radius: 5px;
  padding: 5px;
  width: 300px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  text-align: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p:not(:first-child) {
  text-align: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body {
  padding-bottom: 50px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  column-gap: 2rem;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .MuiAutocomplete-inputRoot::before {
  border-bottom: 1px solid #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .MuiSvgIcon-root {
  fill: #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .actions {
  display: flex;
  padding-left: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .actions i {
  color: #e83e8c;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  padding-bottom: 50px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  column-gap: 2rem;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .MuiAutocomplete-inputRoot::before {
  border-bottom: 1px solid #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .MuiSvgIcon-root {
  fill: #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .actions {
  display: flex;
  justify-content: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .actions i {
  color: #e83e8c;
  font-size: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .dfe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 100px;
}
.ecran_priorisation_evenments .right_content_main .main__content .dfe button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #e83e8c;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

/*# sourceMappingURL=style.css.map */
