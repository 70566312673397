.login_screen {
  @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
  background-color: #fff;
  display: flex;
}
.login_screen .col_1,
.login_screen .col_2 {
  width: 50%;
}
.login_screen .col_1 {
  background: url("/login_img.jpg") no-repeat center center/cover;
  min-height: 100vh;
}
.login_screen .col_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login_screen .col_2 .d-fe {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.login_screen .col_2 .d-fe .forgot_password {
  text-decoration: underline;
}
.login_screen .col_2 .logo {
  margin-bottom: 10px;
}
.login_screen .col_2 .login_btn {
  background: #292c55;
  border-radius: 4px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  /* identical to box height */
  color: #ffffff;
  width: 100%;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}
.login_screen .col_2 form {
  width: 65%;
}
.login_screen .col_2 form .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.login_screen .col_2 form .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background: rgba(217, 217, 217, 0.5);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.login_screen .col_2 form label,
.login_screen .col_2 form .label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
}
.login_screen .col_2 form .input_group {
  margin-bottom: 15px;
}
.login_screen .col_2 form .css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.login_screen .col_2 form .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100%;
  margin-bottom: 10px;
}
.login_screen .col_2 form .css-1nrlq1o-MuiFormControl-root {
  width: 100%;
}/*# sourceMappingURL=style.css.map */