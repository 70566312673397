@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.screen_choose_page_select_ad_page .icon_search_box {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.screen_choose_page_select_ad_page .icon_search_box i {
  color: #e83e8c;
}
.screen_choose_page_select_ad_page .icon_search_box input {
  background-color: transparent;
  border: none;
  flex: 1;
  padding: 10px;
  margin-left: 5px;
  outline: none;
}
.screen_choose_page_select_ad_page .liste_intersect_client {
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 280px;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar {
  width: 10px;
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar-track {
  background: lightgrey;
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar-thumb {
  background: #e83e8c;
  border-radius: 5px;
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar-thumb:hover {
  background: violet;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item:hover, .screen_choose_page_select_ad_page .liste_intersect_client .list_item.selected {
  background-color: #f1f1f1;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left {
  display: flex;
  align-items: center;
  width: 70%;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left .account {
  display: flex;
  align-items: center;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left .account p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left .account img {
  margin-right: 10px;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .right button {
  padding: 10px;
  border-radius: 25px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #292c55;
  background-color: transparent;
  border: 1px solid #292c55;
  cursor: pointer;
  transition: all ease 0.3s;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .right button.active {
  background-color: #292c55;
  color: #fff;
}/*# sourceMappingURL=style.css.map */