@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
.single_contenu_apercu_simple {
  display: flex;
  align-items: flex-start;
  flex: 1;
}
@media (max-width: 768px) {
  .single_contenu_apercu_simple {
    flex-direction: column;
    align-items: center;
  }
}
.single_contenu_apercu_simple .screenshot {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  margin-right: 15px;
  width: 250px;
}
@media (max-width: 768px) {
  .single_contenu_apercu_simple .screenshot {
    margin: 20px 0;
  }
}
.single_contenu_apercu_simple .screenshot .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.single_contenu_apercu_simple .screenshot .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.single_contenu_apercu_simple .screenshot .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
  color: #333;
}
.single_contenu_apercu_simple .screenshot .main_img {
  width: 100%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot {
  position: relative;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot header.entete_fcb {
  padding: 8px 0;
  background-color: #3498db;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot header.entete_fcb .entete_title {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .image img {
  width: 25px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .text h5,
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .right {
  display: flex;
  width: auto !important;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
  padding-left: 0;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .main_img {
  width: 100%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .main_video {
  width: 100%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -3px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
  font-weight: bold;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_cta button a {
  color: #000;
  text-decoration: none;
}/*# sourceMappingURL=style.css.map */