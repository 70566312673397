.single_contenu_apercu_carousel {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
  margin-bottom: 0px;
  margin-right: 10px;
}
.single_contenu_apercu_carousel .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.single_contenu_apercu_carousel .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.single_contenu_apercu_carousel .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
}
.single_contenu_apercu_carousel .main_title_format {
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
}
.single_contenu_apercu_carousel .main_title_format.title_format_story {
  margin-top: 50px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .single_contenu_apercu_carousel {
    flex-direction: column;
    align-items: center;
  }
}
.single_contenu_apercu_carousel .screenshot_carousel {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  width: 250px;
}
.single_contenu_apercu_carousel .screenshot_carousel .title_slide_outside {
  margin-bottom: 5px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .single_contenu_apercu_carousel .screenshot_carousel {
    margin: 20px 0;
  }
}
.single_contenu_apercu_carousel .screenshot_carousel .main_img {
  width: 100%;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot {
  position: relative;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb {
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  outline: none;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected {
  background-color: #e83e8c;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected .entete_title,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected i {
  color: #fff;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input {
  border-color: white;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons img,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons i {
  cursor: pointer;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .entete_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .image img {
  width: 25px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h5,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right {
  display: flex;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right i {
  font-size: 20px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right .fa-times {
  margin-left: 5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper {
  padding-bottom: 10px;
  padding-right: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text {
  padding: 10px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text h5 {
  font-size: 10px;
  font-weight: 900;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text a,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text button {
  background-color: #d8dadf;
  color: #000;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 7px;
  border: none;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  text-align: center;
  display: inline-block;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper-slide {
  width: 80% !important;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .elements_supplementaires {
  position: absolute;
  bottom: 12%;
  right: 30px;
  color: #fff;
  font-size: 20px;
  text-shadow: 1px 1px #000;
  font-family: arial;
  display: none;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .main_img {
  width: 100%;
  height: 160px;
  -o-object-fit: cover;
     object-fit: cover;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook video {
  width: 100%;
  height: 160px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
}/*# sourceMappingURL=style.css.map */