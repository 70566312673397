@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.reports,
.products {
  display: flex;
  /* height: 90vh; */
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
/* form {
  font-size: 16px;
  font-size: max(16px, 1em);
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: #fff;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  bottom: 2rem;
} */
.search-bar-dropdown {
  width: 30em;
  margin-bottom: 1rem;
}

#results {
  position: absolute;
  width: inherit;
  overflow-y: scroll;
  height: 20em;
  /* border-bottom: 1px #eeeeee solid; */
}

#results > button {
  background-color: #fafafa;
}

p {
  color: rgb(116, 116, 116);
}
.aliases {
  margin: 1rem;
  min-width: -webkit-min-content;
  min-width: min-content;
}
.DropSearch {
  text-align: center;
}

.mt {
  margin-top: 20px;
}

body {
  background-color: #f1f1f1;
}

.login_screen {
  background-color: #fff;
  display: flex;
}
.login_screen .col_1,
.login_screen .col_2 {
  width: 50%;
}
.login_screen .col_1 {
  background: url("/login_img.jpg") no-repeat center center/cover;
  min-height: 100vh;
}
.login_screen .col_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login_screen .col_2 .d-fe {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.login_screen .col_2 .d-fe .forgot_password {
  text-decoration: underline;
}
.login_screen .col_2 .logo {
  margin-bottom: 10px;
}
.login_screen .col_2 .login_btn {
  background: #292c55;
  border-radius: 4px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  /* identical to box height */
  color: #ffffff;
  width: 100%;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}
.login_screen .col_2 form {
  width: 65%;
}
.login_screen .col_2 form .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.login_screen .col_2 form .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background: rgba(217, 217, 217, 0.5);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.login_screen .col_2 form label,
.login_screen .col_2 form .label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
}
.login_screen .col_2 form .input_group {
  margin-bottom: 15px;
}
.login_screen .col_2 form .css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.login_screen .col_2 form .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100%;
  margin-bottom: 10px;
}
.login_screen .col_2 form .css-1nrlq1o-MuiFormControl-root {
  width: 100%;
}/*# sourceMappingURL=style.css.map */
.screen_creation_campagne {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_creation_campagne .right_content {
  transition: all ease 0.3s;
}
.screen_creation_campagne .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_creation_campagne .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_creation_campagne .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_creation_campagne .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_campagne .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_campagne .right_content_main {
  padding: 20px 40px;
}
.screen_creation_campagne .right_content_main .choix_compte_publicitaire {
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.screen_creation_campagne .right_content_main .choix_compte_publicitaire p {
  margin-right: 20px;
  color: #000;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}
.screen_creation_campagne .right_content_main .choix_compte_publicitaire select {
  padding: 5px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.screen_creation_campagne .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.screen_creation_campagne .right_content_main .client_advanced_mode .client,
.screen_creation_campagne .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_main .client_advanced_mode .client_title,
.screen_creation_campagne .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_campagne .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.screen_creation_campagne .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  /* or 160% */
  padding: 5px 20px;
  margin: 1rem;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content button i {
  margin-right: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .sticky-button {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  bottom: 10px; /* Adjust based on your layout */
  z-index: 100; /* Ensure it is above other content */
  border: 1px solid #ccc; /* Optional: for better visibility */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 0px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .budget_adset .number {
  border: none;
  min-width: 60px;
  outline: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .nbr_adets,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .budget_adset,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .currency {
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .number {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 1px 2px rgba(232, 62, 140, 0.27);
  border-radius: 2px;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  outline: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .number.number_1 {
  max-width: 60px;
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .number.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: left;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .length {
  margin: 3px;
  align-self: flex-start;
  background: rgba(252, 232, 241, 0.397);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 7px 7px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #292c55;
  width: 40px;
  display: flex;
  justify-content: center;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage {
  margin: 3px;
  align-self: flex-start;
  background-color: #f0f0f0;
  padding: 6px 6px;
  border-radius: 18px;
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage i {
  font-size: 14px;
  cursor: pointer;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage .truncate {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage:hover .truncate {
  white-space: normal; /* Show full text on hover */
  overflow: visible; /* Show full text on hover */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage p,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage i {
  color: #292c55;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* or 200% */
  letter-spacing: 0.15px;
  margin-right: 10px;
  word-break: break-word;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .MuiAutocomplete-root {
  flex: 1 1;
  min-width: 75%; /* Adjust as needed */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .MuiFormControl-root {
  width: 100%; /* Ensures the TextField takes up the available width */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
  word-break: break-all;
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .title .circle {
  width: 8px;
  height: 8px;
  left: 346px;
  top: 828px;
  background: #e83e8c;
  display: inline-block;
  margin-right: 20px;
  border-radius: 50%;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .dates-adset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .dates-adset .form-control {
  font-size: 14px; /* Adjust the font size as needed */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  color: #292c55;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package select {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
  width: 60px;
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .expand-button {
  margin-top: 12px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px; /* Adjust height as needed */
  width: calc(100% + 40px);
  cursor: pointer;
  background-color: rgba(252, 232, 241, 0.397);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .expand-button:hover {
  background-color: rgba(248, 200, 222, 0.603);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_header {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_header p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_header select {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
  width: 70px;
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille .ads_detaille_header,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille .ads_detaille_content .single_package {
  display: grid;
  grid-template-columns: 3fr 70px 4fr 4fr 4fr 200px 95px 20px;
  grid-column-gap: 1rem;
  column-gap: 1rem;
}
.screen_creation_campagne .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_main .sections .section .input_currency {
  margin-left: 5px;
  padding-left: 5px;
  border-left: 0.5px solid rgba(0, 0, 0, 0.4) !important;
}
.screen_creation_campagne .right_content_main .sections .section .container_currency_input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiSelect-select,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiSelect-outlined,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiInputBase-input,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiOutlinedInput-input,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 10px;
  margin-right: 13px;
  font-size: 15px;
}
.screen_creation_campagne .right_content_main .sections .section .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #aaa !important;
}
.screen_creation_campagne .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .rdt {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .input_group .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .MuiInputBase-input,
.screen_creation_campagne .right_content_main .sections .section .input_group .MuiOutlinedInput-input,
.screen_creation_campagne .right_content_main .sections .section .input_group .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border-bottom: none;
}
.screen_creation_campagne .right_content_main .sections .section .input_group select {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .css-oarg8b-MuiAutocomplete-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.screen_creation_campagne .right_content_main .sections .section .input_group input {
  width: 100%;
  font-size: 16px;
}
.screen_creation_campagne .right_content_main .sections .section .input_group input,
.screen_creation_campagne .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.screen_creation_campagne .right_content_main .sections .section .input_group input option,
.screen_creation_campagne .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content {
  position: relative;
  padding: 20px;
  background-color: #fff;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .mode_disabled_no_cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(122, 122, 122, 0.1);
  z-index: 1;
  cursor: not-allowed;
  /* Change the color of input fields when disabled mode is active */
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add {
  margin-top: 20px;
  padding-top: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add .fe button i {
  margin-right: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiInputBase-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiOutlinedInput-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 5px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-2m9kme-MuiFormControl-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiAutocomplete-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-fullWidth,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiTextField-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-fullWidth .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-fullWidth .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .left > * {
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .right {
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .right .row {
  margin-bottom: 0;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .row > * {
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .mode_disabled_no_cursor + input {
  background-color: #f4f4f4; /* Example disabled color */
}
.screen_creation_campagne .right_content_main .sections .section:first-child {
  margin: 0;
}
.screen_creation_campagne .right_content_main .sections .section_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.screen_creation_campagne .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_creation_campagne .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.screen_creation_campagne .right_content_main .sections .section_header .mode_disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(167, 167, 167, 0.5);
  z-index: 1;
  pointer-events: None;
}
.screen_creation_campagne .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_campagne .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}

.inputError {
  border-bottom: 1px solid red;
}

.messageError {
  font-size: 12px;
  color: red;
}

.blockError {
  border: 1px solid red;
}

.warningMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  font-weight: bold;
  background: rgb(255, 247, 231);
  border: 2px solid #ffa500;
  border-radius: 10px;
}
.warningMsg p {
  color: #ffa500;
}
.warningMsg .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 6rem;
  margin: 0.5rem 0;
}
.warningMsg .btn span {
  color: #ffa500;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*# sourceMappingURL=style.css.map */

.alert_section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 99999; /* Ensure the alert is on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.alert_section .sub_section {
  box-shadow: 10px -1px 3px rgba(0, 0, 0, 0.2);
  background-color: #F8C8DE;
  border-radius: 10px;
  padding: 3px;
  left: 70px;
  position: relative;
}
.alert_section .sub_section_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.alert_section .sub_section_header i {
  color: #e83e8c;
}
.alert_section .sub_section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.alert_section .sub_section .alert_msg_config_adset {
  background-color: #fff;
  margin: 2px;
  padding: 10px;
}
.alert_section .sub_section .alert_msg_config_adset .content {
  background-color: #fff;
  padding: 5px;
  white-space: pre-line;
  text-align: center;
  margin: 10px 0px;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons button {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  border: none;
  outline: none;
  padding: 8px 15px;
  border-radius: 18px;
  background-color: #e83e8c;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons button.success {
  color: #FFF;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons button.danger {
  color: #40458A;
}

/*# sourceMappingURL=style.css.map */

.render_tags_option {
  display: flex;
  align-items: center;
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
}
.render_tags_option .circle {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
.render_tags_option p {
  color: #000;
  margin-right: 10px;
  font-family: "Lato", sans-serif;
}/*# sourceMappingURL=style.css.map */
.choix_compte {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
}
.choix_compte .row {
  display: flex;
}
.choix_compte .row .input_group:first-child {
  margin-right: 30px;
}
.choix_compte .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 10px;
  padding-bottom: 10px;
}
.choix_compte .MuiOutlinedInput-notchedOutline,
.choix_compte .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 0;
}
.choix_compte .btn {
  padding: 5px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.choix_compte .input_group {
  margin-right: 20px;
}
.choix_compte .input_group h3 {
  font-size: 18px;
  font-weight: normal;
}
.choix_compte .input_group h3 .info_bulle {
  position: relative;
  display: inline-block;
}
.choix_compte .input_group h3 .info_bulle:hover:before, .choix_compte .input_group h3 .info_bulle:hover:after {
  visibility: visible;
  opacity: 1;
}
.choix_compte .input_group h3 .info_bulle i {
  color: #e83e8c;
  margin-left: 5px;
  font-size: 25px;
}
.choix_compte .input_group h3 .info_bulle:before {
  content: "";
  position: absolute;
  top: 20px;
  left: 5px;
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 8px solid transparent;
  border-bottom: 20px solid #e83e8c;
  border-left: 12px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.choix_compte .input_group h3 .info_bulle:after {
  content: "Your ad will use the page name and profile picture. It will not click through to the actual page on Facebook";
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 150%;
  left: 0;
  background-color: #e83e8c;
  color: #fff;
  font-family: Rubik, sans-serif;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.choix_compte .input_group .form_control {
  width: 300px;
}/*# sourceMappingURL=style.css.map */
.render_geodemo_option {
  display: flex;
  align-items: center;
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
}
.render_geodemo_option .circle {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
.render_geodemo_option p {
  color: #000;
  margin-right: 10px;
  font-family: "Lato", sans-serif;
}/*# sourceMappingURL=style.css.map */
.left_menu {
  background-color: #292c55;
  width: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  transition: all ease 0.3s;
}

.left_menu .logo_seperator {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px; /* Keeps logo near top */
}

.left_menu .logo_seperator img {
  width: 100%;
  max-width: 200px;
  object-fit: contain;
  margin-bottom: 10px; /* Reduced spacing between logo and separator */
}

.left_menu .separator {
  opacity: 0.5;
  border: 0.5px solid #ffffff;
  width: 100%;
  margin-top: 5px; /* Slight spacing above separator */
}

.left_menu .menu {
  list-style: none;
  width: 80%;
  margin-top: 40px;
  margin-bottom: 60px;
}

.left_menu .expand_menu {
  position: absolute;
  top: 50%;
  right: -24px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  background-color: #000;
  z-index: 9999;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.left_menu .toggle_menu {
  position: absolute;
  top: 50%;
  right: -17px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  background-color: #000;
  z-index: 9999;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.left_menu .menu .menu_item {
  display: flex;
  align-items: center;
  margin: 25px 0;
  border-radius: 6px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.left_menu .menu .menu_item:hover, .left_menu .menu .menu_item.active {
  background-color: #fff;
}
.left_menu .menu .menu_item:hover p, .left_menu .menu .menu_item.active p {
  color: #000;
}
.left_menu .menu .menu_item p {
  margin-left: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #ffffff;
}
.left_menu .profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  cursor: pointer;
}
.left_menu .profile i {
  color: #fff;
}
.left_menu .profile .img_title {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.left_menu .profile .img_title p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.left_menu .profile .img_title img {
  margin-right: 10px;
  width: 40px;
  border-radius: 50%;
}/*# sourceMappingURL=style.css.map */
.filter-input-wrapper {
  width: calc(100% - 2rem);
  margin: 0.5rem 1rem;
}
.filter-input-wrapper .filter-input {
  width: 100%;
  margin-bottom: 1rem;
}

.account-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 1rem;
}
.account-items .account-item {
  display: flex;
  align-items: center;
  padding: 0.3rem;
}
.account-items .account-item:hover {
  background: #eee;
}
.account-items .account-item i {
  color: gray;
  font-size: 28px;
  margin-right: 1rem;
}/*# sourceMappingURL=style.css.map */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader .overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader .overlay .content {
  padding: 0px 50px 20px 50px;
  background-color: #fff;
  border-radius: 10px;
}

.center {
  display: flex;
  justify-content: center;
  margin: 20px 0 40px 0;
  position: relative;
  left: -5px;
}

.loader .overlay .content h3 {
  font-size: 24px;
}

.screen_creation_audience {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_creation_audience .right_content {
  transition: all ease 0.3s;
}
.screen_creation_audience .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_creation_audience .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_creation_audience .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_creation_audience .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_audience .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_audience .right_content_main {
  padding: 20px 40px;
}
.screen_creation_audience .right_content_main .advantage_audience_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-top: 40px;
  margin-bottom: 10px;
}
.screen_creation_audience .right_content_main .advantage_audience {
  display: flex;
  align-items: center;
}
.screen_creation_audience .right_content_main .advantage_audience label {
  font-family: "Rubik";
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: inline-block;
  width: 40%;
}
@media (max-width: 1366px) {
  .screen_creation_audience .right_content_main .advantage_audience label {
    width: 47%;
  }
}
.screen_creation_audience .right_content_main .advantage_audience input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  accent-color: #e83e8c;
}
.screen_creation_audience .right_content_main .choix_compte_publicitaire {
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 40px;
}
.screen_creation_audience .right_content_main .choix_compte_publicitaire p {
  margin-right: 20px;
  color: #000;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}
.screen_creation_audience .right_content_main .choix_compte_publicitaire select {
  padding: 5px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.screen_creation_audience .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screen_creation_audience .right_content_main .client_advanced_mode .client,
.screen_creation_audience .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.screen_creation_audience .right_content_main .client_advanced_mode .client_title,
.screen_creation_audience .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_audience .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.screen_creation_audience .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
}
.screen_creation_audience .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.screen_creation_audience .right_content_main .sections .section .label_age_genre {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_audience .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.screen_creation_audience .right_content_main .sections .section .input_group select,
.screen_creation_audience .right_content_main .sections .section .input_group input {
  width: 100%;
}
.screen_creation_audience .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.screen_creation_audience .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.screen_creation_audience .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.screen_creation_audience .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.screen_creation_audience .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_audience .right_content_main .sections .section .input_group input,
.screen_creation_audience .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.screen_creation_audience .right_content_main .sections .section .input_group input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .screen_creation_audience .right_content_main .sections .section .input_group input.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.screen_creation_audience .right_content_main .sections .section .input_group select.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.screen_creation_audience .right_content_main .sections .section .input_group select.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  border-bottom: none;
}
.screen_creation_audience .right_content_main .sections .section .input_group input option,
.screen_creation_audience .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.screen_creation_audience .right_content_main .sections .section .inputError {
  border-bottom: 1px solid red;
}
.screen_creation_audience .right_content_main .sections .section .messageError {
  font-size: 12px;
  color: red;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content {
  padding: 20px;
  background-color: #fff;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .add_audience .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .add_audience .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .add_audience .fe button i {
  margin-right: 10px;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .add .fe button i {
  margin-right: 10px;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .css-2m9kme-MuiFormControl-root,
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .date_budget .left > * {
  width: 45%;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .date_budget .right {
  width: 45%;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .date_budget .right .row {
  margin-bottom: 0;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_creation_audience .right_content_main .sections .section .informations_generales_content .row > * {
  width: 45%;
}
.screen_creation_audience .right_content_main .sections .section:first-child {
  margin: 0;
}
.screen_creation_audience .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.screen_creation_audience .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_creation_audience .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.screen_creation_audience .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_audience .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */
.single_contenu_apercu_simple {
  display: flex;
  align-items: flex-start;
  flex: 1 1;
}
@media (max-width: 768px) {
  .single_contenu_apercu_simple {
    flex-direction: column;
    align-items: center;
  }
}
.single_contenu_apercu_simple .screenshot {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  margin-right: 15px;
  width: 250px;
}
@media (max-width: 768px) {
  .single_contenu_apercu_simple .screenshot {
    margin: 20px 0;
  }
}
.single_contenu_apercu_simple .screenshot .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.single_contenu_apercu_simple .screenshot .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.single_contenu_apercu_simple .screenshot .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
  color: #333;
}
.single_contenu_apercu_simple .screenshot .main_img {
  width: 100%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot {
  position: relative;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot header.entete_fcb {
  padding: 8px 0;
  background-color: #3498db;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot header.entete_fcb .entete_title {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .image img {
  width: 25px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .text h5,
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .right {
  display: flex;
  width: auto !important;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
  padding-left: 0;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .main_img {
  width: 100%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .main_video {
  width: 100%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -3px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
  font-weight: bold;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
}
.single_contenu_apercu_simple .screenshot.facebook_screenshot .screenshot_footer .screenshot_footer_cta button a {
  color: #000;
  text-decoration: none;
}/*# sourceMappingURL=style.css.map */
.creation_package_pub_screen {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_package_pub_screen .right_content {
  transition: all ease 0.3s;
}
.creation_package_pub_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_package_pub_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_header .back_title .back {
  cursor: pointer;
}
.creation_package_pub_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_main {
  padding: 20px 40px;
}
.creation_package_pub_screen .right_content_main .wrapper_compte_cat_pub {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .choix_compte_publicitaire {
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .choix_compte_publicitaire p {
  margin-right: 20px;
  color: #000;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}
.creation_package_pub_screen .right_content_main .choix_compte_publicitaire select {
  padding: 5px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.creation_package_pub_screen .right_content_main .cata_pub {
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .cata_pub p {
  margin-right: 20px;
  color: #000;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}
.creation_package_pub_screen .right_content_main .row {
  display: flex;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left {
  width: 65%;
  transition: all ease 0.1s;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs {
  margin-bottom: 50px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input[type=text] {
  font-size: 16px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::placeholder {
  font-size: 16px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input {
  width: 400px;
  outline: none;
  display: block;
  margin: 20px 0;
  padding: 5px 5px 5px 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input.MuiInputBase-input {
  padding: 0;
  border-bottom: none;
  margin: 10px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 50px;
  gap: 50px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_catalog {
  flex: 1 1;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_catalog label {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_product {
  flex: 1 1;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_product label {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .checkbox_container {
  margin: 2rem 0;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .checkbox_container .text_label {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .checkbox_container .checkbox_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
  grid-gap: 70px;
  gap: 70px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.5s;
}
.creation_package_pub_screen .right_content_main .row.row_1 .right .pxt {
  padding-left: 10px;
  padding-right: 10px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .right .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #858585;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .client_advanced_mode .client,
.creation_package_pub_screen .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .client_advanced_mode .client_title,
.creation_package_pub_screen .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.creation_package_pub_screen .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.creation_package_pub_screen .right_content_main .sections .section.section_publicites_existantes .section_header .nbr {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes {
  padding: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes {
  display: flex;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .add_publicite_existante {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin: 10px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante {
  margin: 10px;
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags {
  margin: 5px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags .tag {
  border: 1px solid #e83e8c;
  color: #fff;
  border-radius: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags .tag i {
  color: #e83e8c;
  font-size: 14px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags .tag p {
  color: #e83e8c;
  font-size: 10px;
  margin-right: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected {
  border: 1px solid #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected .delete_pub {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected .publicite_existante_title,
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected a {
  background-color: #e83e8c;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .delete_pub {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .image_container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-top: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante img {
  max-width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: space-around;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .interactions p {
  display: flex;
  align-items: center;
  font-size: 8px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 8px;
  color: #333;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .publicite_existante_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  /* or 400% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .list_carousels {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .list_carousels .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  margin-left: 15px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .list_posts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .list_posts .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .pagination {
  display: flex;
  justify-content: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .pagination .Mui-selected {
  background-color: #292c55;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .wrapper .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text {
  margin-bottom: 20px;
  width: 75%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text i {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text input,
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #292c55;
  flex: 1 1;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text input::placeholder,
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected p.main_para {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .inputError {
  border-bottom: 1px solid red;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .messageError {
  font-size: 12px;
  color: red;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .wrapper .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text {
  margin-bottom: 20px;
  width: 75%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text i {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text input,
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  flex: 1 1;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text input::placeholder,
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  width: 48%;
  margin-bottom: 20px;
  transition: all ease 0.3s;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected p.main_para {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected .tags {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected h5 {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected h5 {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .nbr, .creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .nbr {
  margin-left: 20px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr .circle, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search .fa-search, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input:placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags p, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title .title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title .title {
  width: 80%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image {
  position: relative;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .left, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item_right, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag i, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag p, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected .image_title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.add_image_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-left: 10px;
  margin-right: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .left, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item_right, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag i, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag p, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected .image_title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.add_image_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 260px;
  height: 310px !important;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image video, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image video {
  width: 260px;
  height: 260px;
  object-fit: contain;
  object-position: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .pagination, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group select {
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group input,
.creation_package_pub_screen .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group input option,
.creation_package_pub_screen .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.creation_package_pub_screen .right_content_main .sections .section_content {
  padding: 20px;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.creation_package_pub_screen .right_content_main .sections .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.creation_package_pub_screen .right_content_main .sections .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.creation_package_pub_screen .right_content_main .sections .section_content .add .fe button i {
  margin-right: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section_content .css-2m9kme-MuiFormControl-root,
.creation_package_pub_screen .right_content_main .sections .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .left > * {
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .right {
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section_content .row > * {
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section:first-child {
  margin: 0;
}
.creation_package_pub_screen .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_package_pub_screen .right_content_main .d-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .d-sb button {
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .d-sb .standard {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
}
.creation_package_pub_screen .right_content_main .d-sb .outline {
  padding: 10px 20px;
  border-radius: 25px;
  color: #e83e8c;
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  transition: all ease 0.3s;
}
.creation_package_pub_screen .right_content_main .d-sb .outline:hover {
  background-color: #e83e8c;
  color: #ffffff;
}
.creation_package_pub_screen .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.creation_package_pub_screen .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}

.creation_package_pub_screen .right_content_main .sections .section_texte .section_header_title .nbr,
.creation_package_pub_screen .right_content_main .sections .section_description .section_header_title .nbr {
  height: 30px;
  width: 30px;
}

.section_appairer_des_visuels {
  position: fixed;
  z-index: 9999;
  top: 70px;
  bottom: 0px;
  right: 0px;
  width: 80%;
  background-color: #fff;
  padding: 30px 20px;
  overflow-y: scroll;
  max-height: 100%;
}
.section_appairer_des_visuels .video_item .image_title,
.section_appairer_des_visuels .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.section_appairer_des_visuels .video_item .image_title .title,
.section_appairer_des_visuels .image_item .image_title .title {
  width: 80%;
}
.section_appairer_des_visuels .video_item .image,
.section_appairer_des_visuels .video_item .video,
.section_appairer_des_visuels .image_item .image,
.section_appairer_des_visuels .image_item .video {
  position: relative;
}
.section_appairer_des_visuels .video_item {
  position: relative;
  overflow: hidden;
}
.section_appairer_des_visuels .video_item video {
  width: 100%;
}
.section_appairer_des_visuels .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.section_appairer_des_visuels .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .video_item_actions button:last-child {
  background-color: #292c55;
}
.section_appairer_des_visuels .image_item {
  position: relative;
  overflow: hidden;
}
.section_appairer_des_visuels .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.section_appairer_des_visuels .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .image_item_actions button:last-child {
  background-color: #292c55;
}
.section_appairer_des_visuels .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.section_appairer_des_visuels .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.section_appairer_des_visuels .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.section_appairer_des_visuels .main__header i {
  color: #292c55;
  font-size: 25px;
}
.section_appairer_des_visuels .main__content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content_left {
  width: 66%;
  padding-left: 20px;
}
.section_appairer_des_visuels .main__content_right {
  width: 30%;
  padding-right: 20px;
}
.section_appairer_des_visuels .main__content_right .button_container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.section_appairer_des_visuels .main__content_right .button_container button {
  padding: 10px 50px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  color: #fff;
  letter-spacing: 0.15px;
  background: rgba(41, 44, 85, 0.25);
  border: none;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content_right .button_container button.active {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .main__content_right .carre {
  margin-bottom: 50px;
}
.section_appairer_des_visuels .main__content_right .carre .element > div {
  min-height: 332px;
}
.section_appairer_des_visuels .main__content_right .carre .element img:not(.default) {
  min-height: 332px;
  object-fit: cover;
}
.section_appairer_des_visuels .main__content_right .carre .element video {
  min-height: 332px;
}
.section_appairer_des_visuels .main__content_right .story .element video {
  min-height: 600px;
}
.section_appairer_des_visuels .main__content_right .carre .title,
.section_appairer_des_visuels .main__content_right .story .title {
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;
  /* or 225% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #e83e8c;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
}
.section_appairer_des_visuels .main__content_right .carre .element,
.section_appairer_des_visuels .main__content_right .story .element {
  min-height: 332px;
  background: rgba(41, 44, 85, 0.06);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.section_appairer_des_visuels .main__content_right .carre .element .center,
.section_appairer_des_visuels .main__content_right .story .element .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 277px;
  height: 100%;
  margin: 0;
  left: unset;
  position: static;
}
.section_appairer_des_visuels .main__content_right .carre .element.element_story,
.section_appairer_des_visuels .main__content_right .story .element.element_story {
  min-height: 600px;
}
.section_appairer_des_visuels .main__content_right .carre .element h5,
.section_appairer_des_visuels .main__content_right .story .element h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 85, 0.7);
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 50px 0;
  position: relative;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr, .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr .circle, .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr img, .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search .fa-search, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search input, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search input:placeholder, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .tags p, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search,
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search,
.section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .left, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item_right, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags .tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags .tag i, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags .tag p, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .add_tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .add_tag input, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item.add_image_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image img,
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image video, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image img,
.section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image video, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .left, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item_right, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags .tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags .tag i, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags .tag p, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .add_tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .add_tag input, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item.selected .image_title, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item.selected .image_title {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item.selected .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item.add_image_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .image img,
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .image video, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .image img,
.section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .image video {
  width: 230px;
  height: 230px;
  object-fit: cover;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .pagination, .section_appairer_des_visuels .main__content .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content .section .df {
  display: flex;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.section_appairer_des_visuels .main__content .section .input_group select {
  width: 100%;
}
.section_appairer_des_visuels .main__content .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.section_appairer_des_visuels .main__content .section .input_group.centre_interet select {
  width: 100%;
}
.section_appairer_des_visuels .main__content .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content .section .input_group.objectif select {
  width: 100%;
}
.section_appairer_des_visuels .main__content .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section .input_group input,
.section_appairer_des_visuels .main__content .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.section_appairer_des_visuels .main__content .section .input_group input option,
.section_appairer_des_visuels .main__content .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.section_appairer_des_visuels .main__content .section_content {
  padding: 5px;
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.section_appairer_des_visuels .main__content .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.section_appairer_des_visuels .main__content .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.section_appairer_des_visuels .main__content .section_content .add .fe button i {
  margin-right: 10px;
}
.section_appairer_des_visuels .main__content .section_content .css-2m9kme-MuiFormControl-root,
.section_appairer_des_visuels .main__content .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.section_appairer_des_visuels .main__content .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .left > * {
  width: 45%;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .right {
  width: 45%;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.section_appairer_des_visuels .main__content .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.section_appairer_des_visuels .main__content .section_content .row > * {
  width: 45%;
}
.section_appairer_des_visuels .main__content .section:first-child {
  margin: 0;
}
.section_appairer_des_visuels .main__content .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section_header i {
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}

.blockError {
  border: 1px solid red;
}/*# sourceMappingURL=style.css.map */
.associated_assets {
  width: 30px;
  height: 30px;
  background: #292c55;
  border-radius: 10px;
  color: #e83e8c;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=style.css.map */
.search_asset {
  margin-bottom: 20px;
}
.search_asset input {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  border: none;
  min-width: 250px;
  padding: 5px;
  outline: none;
  border-bottom: 1px solid #e83e8c;
}
.search_asset input::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.search_asset i {
  color: #e83e8c;
  margin-right: 5px;
}/*# sourceMappingURL=style.css.map */
.single_contenu_apercu_carousel {
  margin-bottom: 0px;
  margin-right: 10px;
}
.single_contenu_apercu_carousel .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.single_contenu_apercu_carousel .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.single_contenu_apercu_carousel .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
}
.single_contenu_apercu_carousel .main_title_format {
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
}
.single_contenu_apercu_carousel .main_title_format.title_format_story {
  margin-top: 50px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .single_contenu_apercu_carousel {
    flex-direction: column;
    align-items: center;
  }
}
.single_contenu_apercu_carousel .screenshot_carousel {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  width: 250px;
}
.single_contenu_apercu_carousel .screenshot_carousel .title_slide_outside {
  margin-bottom: 5px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .single_contenu_apercu_carousel .screenshot_carousel {
    margin: 20px 0;
  }
}
.single_contenu_apercu_carousel .screenshot_carousel .main_img {
  width: 100%;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot {
  position: relative;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb {
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  outline: none;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected {
  background-color: #e83e8c;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected .entete_title,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected i {
  color: #fff;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input {
  border-color: white;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons img,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons i {
  cursor: pointer;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .entete_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .image img {
  width: 25px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h5,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right {
  display: flex;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right i {
  font-size: 20px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right .fa-times {
  margin-left: 5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper {
  padding-bottom: 10px;
  padding-right: 10px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text {
  padding: 10px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -5px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text h5 {
  font-size: 10px;
  font-weight: 900;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text a,
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text button {
  background-color: #d8dadf;
  color: #000;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 7px;
  border: none;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  text-align: center;
  display: inline-block;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper-slide {
  width: 80% !important;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .elements_supplementaires {
  position: absolute;
  bottom: 12%;
  right: 30px;
  color: #fff;
  font-size: 20px;
  text-shadow: 1px 1px #000;
  font-family: arial;
  display: none;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .main_img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook video {
  width: 100%;
  height: 160px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
}
.single_contenu_apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
}/*# sourceMappingURL=style.css.map */
.facebook_screenshot {
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 250px;
  margin-bottom: 50px;
  cursor: pointer;
}
.facebook_screenshot .subattachments {
  display: flex;
  position: relative;
}
.facebook_screenshot .subattachments img {
  width: 33.32%;
  border: 1px solid #fff;
}
.facebook_screenshot .subattachments img.w-50 {
  width: 50%;
  height: 125px;
  object-fit: cover;
}
.facebook_screenshot .subattachments .nbr_subattachments_left {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  width: 100%;
  font-size: 20px;
}
.facebook_screenshot .main_img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.facebook_screenshot .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.facebook_screenshot .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.facebook_screenshot .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
}
.facebook_screenshot header.entete_fcb {
  padding: 8px 0;
  background-color: #3498db;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.facebook_screenshot header.entete_fcb.selected {
  background-color: #e83e8c;
  color: #fff;
}
.facebook_screenshot header.entete_fcb .entete_title {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.facebook_screenshot .account .left .image img {
  width: 25px;
}
.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.facebook_screenshot .account .left .text h5,
.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.facebook_screenshot .account .right {
  display: flex;
  flex-direction: row !important;
  width: auto !important;
}
.facebook_screenshot .account .right .fa-times {
  margin-left: 10px;
}
.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.facebook_screenshot .main_img {
  width: 100%;
}
.facebook_screenshot .main_video {
  width: 100%;
}
.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -3px;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
  font-weight: bold;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
}
.facebook_screenshot .commentaires_partages {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.facebook_screenshot .commentaires_partages p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  /* identical to box height, or 121% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.facebook_screenshot .commentaires_partages .droite {
  display: flex;
  align-items: center;
}
.facebook_screenshot .commentaires_partages .droite p:last-child {
  margin-left: 5px;
}
.facebook_screenshot .commentaires_partages .gauche {
  display: flex;
  align-items: center;
}
.facebook_screenshot .commentaires_partages .gauche p {
  margin-left: 5px;
}
.facebook_screenshot .commentaires_partages .gauche .like,
.facebook_screenshot .commentaires_partages .gauche .love {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebook_screenshot .commentaires_partages .gauche .like i,
.facebook_screenshot .commentaires_partages .gauche .love i {
  color: #fff;
  font-size: 7px;
}
.facebook_screenshot .commentaires_partages .gauche .like {
  background-color: #3498db;
}
.facebook_screenshot .commentaires_partages .gauche .love {
  background-color: #e74c3c;
}/*# sourceMappingURL=style.css.map */
.instagram_screenshot {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 250px;
  margin-bottom: 50px;
  cursor: pointer;
  padding-bottom: 10px;
}
.instagram_screenshot .main_img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.instagram_screenshot .px {
  padding-left: 5px;
  padding-right: 5px;
}
.instagram_screenshot .main_video {
  width: 100%;
}
.instagram_screenshot header.entete_insta {
  padding: 3px 0;
  background-color: rgba(204, 204, 204, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.instagram_screenshot header.entete_insta.selected {
  background-color: #e83e8c;
  color: #fff;
}
.instagram_screenshot header.entete_insta.selected .title {
  color: #fff;
}
.instagram_screenshot header.entete_insta .title {
  font-family: "Rubik";
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}
.instagram_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
}
.instagram_screenshot .account .left {
  display: flex;
  align-items: center;
}
.instagram_screenshot .account .left .image img {
  width: 25px;
}
.instagram_screenshot .account .left .text {
  margin-left: 5px;
}
.instagram_screenshot .account .left .text h5,
.instagram_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.instagram_screenshot .account .right {
  display: flex;
  width: auto !important;
}
.instagram_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.instagram_screenshot .learn_more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.instagram_screenshot .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
.instagram_screenshot .actions .right {
  width: auto !important;
}
.instagram_screenshot .actions .center {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.instagram_screenshot .actions .center span {
  width: 7px;
  height: 7px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
}
.instagram_screenshot .actions .center span.active {
  background-color: #3498db;
}
.instagram_screenshot .actions img {
  width: 15px;
  margin: 0 3px;
}
.instagram_screenshot .pxy {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
.instagram_screenshot .views {
  font-size: 8px;
  margin-top: 10px;
  font-weight: normal;
}
.instagram_screenshot .title {
  font-size: 10px;
  margin: 5px 0;
  font-weight: normal;
  color: #000;
}
.instagram_screenshot .comments_date h6,
.instagram_screenshot .comments_date p {
  font-size: 8px;
  font-weight: normal;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_parametres {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_parametres .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_parametres .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_parametres .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_parametres .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_parametres .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_parametres .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_parametres .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_parametres .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_parametres .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_parametres .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_parametres .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_parametres .right_content_main .compte {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.ecran_campagne_parametres .right_content_main .compte_content {
  width: 68%;
  padding: 50px 0;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_parametres .right_content_main .compte_content h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_parametres .right_content_main .compte_content p {
  margin: 30px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_parametres .right_content_main .compte_content button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  padding: 10px 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
  border: none;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_ajouter_compte {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_ajouter_compte .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_ajouter_compte .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_ajouter_compte .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_ajouter_compte .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_ajouter_compte .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_ajouter_compte .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_ajouter_compte .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_ajouter_compte .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_ajouter_compte .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_ajouter_compte .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_ajouter_compte .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content {
  width: 68%;
  padding: 50px 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #000000;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 60px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok img {
  margin: 0 20px;
  border: 1px solid transparent;
  padding: 5px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok img.selected {
  border: 1px solid rgba(232, 62, 140, 0.5);
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .meta_tiktok img.selectedMeta {
  border: 1px solid rgba(255, 0, 0, 0.5);
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .navigation button {
  margin: 0 50px;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .navigation button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  padding: 10px 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
  border: none;
}
.ecran_campagne_ajouter_compte .right_content_main .choix_reseau_social_content .navigation button.outline {
  background-color: transparent;
  border: 1px solid #e83e8c;
  color: #e83e8c;
  padding: 10px 20px;
}/*# sourceMappingURL=style.css.map */
.popup_session_expired_facebook {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.popup_session_expired_facebook .content {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
}
.popup_session_expired_facebook .content h2,
.popup_session_expired_facebook .content h3,
.popup_session_expired_facebook .content h4 {
  text-align: center;
}
.popup_session_expired_facebook .content h3 {
  margin: 20px 0;
}
.popup_session_expired_facebook .content h4 {
  margin-bottom: 10px;
}
.popup_session_expired_facebook .content .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup_session_expired_facebook .content .buttons button {
  font-size: 17px;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.popup_session_expired_facebook .content .buttons button.success {
  background-color: #3498db;
}
.popup_session_expired_facebook .content .buttons button.danger {
  background-color: #e74c3c;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_choisir_compte {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_choisir_compte .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_choisir_compte .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_choisir_compte .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_choisir_compte .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_choisir_compte .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_choisir_compte .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_choisir_compte .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_choisir_compte .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_choisir_compte .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_choisir_compte .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_choisir_compte .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content {
  width: 68%;
  padding: 50px 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta {
  width: 500px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta header {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta header img {
  width: 80px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta header h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta .form_control {
  margin-top: 10px;
  margin-bottom: 100px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta .MuiOutlinedInput-notchedOutline,
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #000000;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .meta_tiktok {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 100px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .meta_tiktok img {
  margin: 0 20px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation {
  margin-top: 50px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button {
  margin: 0 50px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button {
  border-radius: 144px;
  padding: 10px 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button.plain {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  color: #ffffff;
  background: #e83e8c;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button.outline {
  border: 1px solid #e83e8c;
  color: #e83e8c;
}/*# sourceMappingURL=style.css.map */
.screen_choose_account_select_ad_account .icon_search_box {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.screen_choose_account_select_ad_account .icon_search_box i {
  color: #e83e8c;
}
.screen_choose_account_select_ad_account .icon_search_box input {
  background-color: transparent;
  border: none;
  flex: 1 1;
  padding: 10px;
  margin-left: 5px;
  outline: none;
}
.screen_choose_account_select_ad_account .liste_intersect_client {
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 280px;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.screen_choose_account_select_ad_account .liste_intersect_client::-webkit-scrollbar {
  width: 10px;
}
.screen_choose_account_select_ad_account .liste_intersect_client::-webkit-scrollbar-track {
  background: lightgrey;
}
.screen_choose_account_select_ad_account .liste_intersect_client::-webkit-scrollbar-thumb {
  background: #e83e8c;
  border-radius: 5px;
}
.screen_choose_account_select_ad_account .liste_intersect_client::-webkit-scrollbar-thumb:hover {
  background: violet;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item:hover, .screen_choose_account_select_ad_account .liste_intersect_client .list_item.selected {
  background-color: #f1f1f1;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item .left {
  display: flex;
  align-items: center;
  width: 70%;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item .left input {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item .left .account {
  display: flex;
  align-items: center;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item .left .account p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item .left .account img {
  margin-right: 10px;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item .right button {
  padding: 10px;
  border-radius: 25px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #292c55;
  background-color: transparent;
  border: 1px solid #292c55;
  cursor: pointer;
  transition: all ease 0.3s;
}
.screen_choose_account_select_ad_account .liste_intersect_client .list_item .right button.active {
  background-color: #292c55;
  color: #fff;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_configurer_strategie {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_configurer_strategie .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_configurer_strategie .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
}
.ecran_campagne_configurer_strategie .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_configurer_strategie .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_configurer_strategie .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_configurer_strategie .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_configurer_strategie .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_configurer_strategie .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_configurer_strategie .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion .dfe button {
  border-radius: 144px;
  padding: 10px 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  color: #ffffff;
  background: #e83e8c;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group .css-574lq2-MuiFormControl-root {
  margin-top: 0;
  margin-left: 0;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: block;
}
.ecran_campagne_configurer_strategie .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}

.ecran_campagne_configurer_strategie_2 {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_configurer_strategie_2 .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_configurer_strategie_2 .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group {
  margin-bottom: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group .css-574lq2-MuiFormControl-root {
  margin-top: 0;
  margin-left: 0;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: block;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .right button {
  background-color: transparent;
  border-radius: 86px;
  padding: 0px 10px;
  border: 1px solid #e83e8c;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .left {
  display: flex;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .left .css-gcwvw8-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .left .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .left i {
  color: #e83e8c;
  font-size: 20px;
  margin-right: 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  table-layout: fixed;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr td:first-child {
  padding-left: 50px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr td:last-child {
  padding-left: 60px;
  padding-right: 60px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_configurer_strategie_2 {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_configurer_strategie_2 .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_configurer_strategie_2 .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .right button {
  background: #e83e8c;
  border-radius: 86px;
  padding: 5px 30px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 218% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left {
  display: flex;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left .css-gcwvw8-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left i {
  color: #e83e8c;
  font-size: 20px;
  margin-right: 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_comptes_actifs {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_comptes_actifs .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_comptes_actifs .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_comptes_actifs .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_comptes_actifs .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_comptes_actifs .right_content_main .btn_primary_account {
  padding: 5px 20px;
  background-color: #292c55;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #fff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
.ecran_campagne_comptes_actifs .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_comptes_actifs .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_comptes_actifs .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_comptes_actifs .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie {
  margin-top: 60px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 0px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn .row {
  display: flex;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn .row button {
  margin: 0 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn button {
  border-radius: 144px;
  padding: 5px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border: 1px solid #e83e8c;
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe.dfe_2 {
  margin-top: 60px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  border: none;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  flex: 1 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th:first-child {
  padding-left: 60px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody tr td:first-child {
  padding-left: 50px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row {
  display: flex;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row > * {
  margin: 0 10px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}/*# sourceMappingURL=style.css.map */
.ecran_liste_campagnes {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_liste_campagnes .right_content {
  transition: all ease 0.3s;
}
.ecran_liste_campagnes .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 999;
}
.ecran_liste_campagnes .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_liste_campagnes .right_content_header .create_campaign {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ecran_liste_campagnes .right_content_header .create_campaign button {
  background: #e83e8c;
  box-shadow: 0px 5px 11px rgba(232, 62, 140, 0.25);
  border-radius: 79px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main {
  padding: 20px 40px;
  position: relative;
}
.ecran_liste_campagnes .right_content_main .data {
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow-x: auto;
  margin-top: 30px;
  position: relative;
  width: 100%;
  overflow-y: hidden;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media (min-width: 1900px) {
  .ecran_liste_campagnes .right_content_main .data {
    max-width: 1440px;
  }
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar {
  height: 15px;
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar-track {
  background: lightgrey;
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar-thumb {
  background: rgba(0, 102, 255, 0.8);
  border-radius: 10px;
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar-thumb:hover {
  background: #0066ff;
}
.ecran_liste_campagnes .right_content_main .data table {
  border-collapse: collapse;
  border: none;
  position: relative;
  table-layout: auto;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
}
.ecran_liste_campagnes .right_content_main .data table .meta header {
  width: 100%;
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  padding: 15px;
  padding-left: 35px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .right {
  position: -webkit-sticky;
  position: sticky;
  right: 20px;
  top: 0;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .right i {
  color: #e83e8c;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .left {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  left: 20px;
  top: 0;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .left .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .left img {
  margin-right: 20px;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr td.first_column, .ecran_liste_campagnes .right_content_main .data table tbody tr td.second_column {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr td.first_column {
  left: -1px;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr td.second_column {
  left: 50px;
  border-right: 0.5px solid rgba(0, 0, 0, 0.13) !important;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr:not(.brouillon):nth-child(even) td {
  background: #e5eaf1;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr:not(.brouillon):nth-child(odd) td {
  background: #fff;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.troisieme_cas td {
  background-color: rgba(232, 62, 140, 0.7);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne {
  border-bottom: 1px solid rgba(238, 238, 238, 0.4);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne:hover td {
  background-color: #e5eaf1;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne td {
  border: 0.5px solid rgba(0, 0, 0, 0.13);
  padding: 10px 0;
  transition: all ease 0.3s;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne .brouillon {
  display: block;
  text-align: right;
  margin: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon {
  background-color: #f9f9f9;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon.troisieme_cas td.first_column, .ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon.troisieme_cas td.second_column {
  background-color: rgba(232, 62, 140, 0.7);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td {
  padding: 10px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(41, 44, 85, 0.69);
  border: 0.5px solid rgba(0, 0, 0, 0.13);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.first_column, .ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.second_column {
  background-color: #f9f9f9;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.13);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.second_column {
  border-right: 1px solid #f9f9f9;
  left: 20px !important;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.first {
  background-color: #f9f9f9;
  padding-left: 10px;
  border-left: none;
  padding-right: 10px;
  min-width: 400px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td {
  padding: 10px;
  text-align: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  color: #000000;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .Mui-checked {
  color: #0066ff;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .MuiSwitch-track {
  background-color: #0066ff;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .cree_par_img {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  padding-left: 10px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .actions i {
  margin-left: 20px;
  cursor: pointer;
  font-size: 20px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .actions img {
  margin: 0 7px;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_main .data table tbody td.first {
  text-align: left;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  color: #000000;
}
.ecran_liste_campagnes .right_content_main .data table tbody td.first p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_not_ok {
  position: relative;
  border: 2px solid #e70d0d;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_not_ok:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e70d0d;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_not_ok_2 {
  position: relative;
  border: 2px solid #e70d0d;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok {
  position: relative;
  border: 2px solid #4cc937;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4cc937;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok_2 {
  position: relative;
  border: 2px solid #4cc937;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_med {
  position: relative;
  border: 2px solid orange;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_med:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: orange;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok_2 {
  position: relative;
  border: 2px solid orange;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table thead {
  border-radius: 10px 10px 0px 0px;
}
.ecran_liste_campagnes .right_content_main .data table thead th.first_status, .ecran_liste_campagnes .right_content_main .data table thead th.name_campaign, .ecran_liste_campagnes .right_content_main .data table thead th.last {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 888;
}
.ecran_liste_campagnes .right_content_main .data table thead th.first_status {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.13) !important;
}
.ecran_liste_campagnes .right_content_main .data table thead th.last {
  right: 0;
}
.ecran_liste_campagnes .right_content_main .data table thead th.name_campaign {
  left: -1px;
}
.ecran_liste_campagnes .right_content_main .data table thead .df {
  display: flex;
  align-items: center;
}
.ecran_liste_campagnes .right_content_main .data table thead .dynamic_columns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.ecran_liste_campagnes .right_content_main .data table thead .couts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ecran_liste_campagnes .right_content_main .data table thead .couts p {
  font-size: 12px;
}
.ecran_liste_campagnes .right_content_main .data table thead .couts input {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_main .data table thead th {
  background-color: #fff;
  z-index: 99;
  text-align: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-collapse: collapse;
  position: -webkit-sticky;
  position: sticky;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main .data table thead th.first {
  text-align: left;
}
.ecran_liste_campagnes .right_content_main .data table thead th.last {
  padding-right: 20px;
}
.ecran_liste_campagnes .right_content_main .informations_compte {
  background-color: #fff;
  margin-top: 50px;
  border-radius: 0 0 5px 5px;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content {
  padding: 20px 30px;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 25px;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul li {
  margin: 15px 0;
  position: relative;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul li:before {
  content: "";
  background-color: #e83e8c;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul li p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main .informations_compte header {
  padding: 20px 30px;
  background: rgba(41, 44, 85, 0.15);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_liste_campagnes .right_content_main .informations_compte header .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}/*# sourceMappingURL=style.css.map */
.campaign-element {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 2.5rem;
  border-radius: 0;
}
.campaign-element .wrapper {
  width: 76%;
}
.campaign-element .wrapper .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff4f9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
}
.campaign-element .wrapper .content {
  display: flex;
  justify-content: center;
  height: 76vh;
  overflow-y: scroll;
  background-color: #fff;
  padding: 20px;
  border-radius: 0;
}
.campaign-element .wrapper .content .ligne-elem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaign-element .wrapper .content tbody tr:nth-child(even) td {
  background: #eef0f4;
  padding: 0 2rem;
}
.campaign-element .wrapper .content tbody tr:nth-child(odd) td {
  background: #fff;
  padding: 0 2rem;
}
.campaign-element .wrapper .content tbody input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  outline: none;
  margin-left: 1rem;
}
.campaign-element .wrapper .content tbody button {
  background: transparent;
  border: none;
  color: #e83e8c;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}
.campaign-element .wrapper .content tbody button i {
  margin: 0 10px;
}
.campaign-element .wrapper .content tbody button i:hover {
  color: #e83e8c;
}
.campaign-element .wrapper .footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
}
.campaign-element .wrapper .footer button {
  padding: 5px 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0px 10px;
  border: none;
  /* or 133% */
  cursor: pointer;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.campaign-element .wrapper .footer button.update {
  background-color: #e83e8c;
}
.campaign-element .wrapper .footer button.save {
  background-color: #e83e8c;
}/*# sourceMappingURL=style.css.map */
.filtres {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f1f1f1;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 1000;
}
.filtres .border_rd {
  border: 1px solid #e83e8c;
  border-radius: 5px;
  padding: 5px;
}
.filtres .filter_date {
  margin-right: 20px;
  min-width: 150px;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #e83e8c;
  background-color: transparent;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("/down-arrow.png");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 15px;
}
.filtres .creer_par input,
.filtres .creer_par select,
.filtres .creer_par option,
.filtres .compte_client input,
.filtres .compte_client select,
.filtres .compte_client option {
  outline: none;
  border: none;
  background-color: transparent;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.filtres .creer_par select,
.filtres .compte_client select {
  width: 150px;
  padding-bottom: 5px;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("/down-arrow.png");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  background-size: 15px;
}
.filtres .date_debut {
  margin-right: 20px;
}
.filtres .date_fin {
  margin-right: 30px;
  transition: all ease 0.3s;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes shakeInput {
  0% {
    transform: translate(0, 0);
  }
  10%, 90% {
    transform: translate(-5px, 0);
  }
  20%, 80% {
    transform: translate(5px, 0);
  }
  30%, 50%, 70% {
    transform: translate(-5px, 0);
  }
  40%, 60% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.filtres .date {
  display: flex;
  align-items: center;
  position: relative;
  width: 150px;
  height: 30px;
}
.filtres .date img {
  margin-right: 5px;
  position: absolute;
  right: 10px;
  z-index: 9;
}
.filtres .date input {
  border: none;
  outline: none;
  background-color: transparent;
  margin-left: 10px;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  padding: 5px;
}
.filtres .date input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 20px;
  width: 100%;
  z-index: 999999;
  cursor: pointer;
}
.filtres .creer_par {
  margin-right: 20px;
}
.filtres p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  /* identical to box height, or 187% */
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 20px;
}/*# sourceMappingURL=style.css.map */
.modal_filter_insights_by_date {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal_filter_insights_by_date .content {
  border-radius: 20px;
  background-color: #fff;
  width: 400px;
  padding: 20px;
}
.modal_filter_insights_by_date .content h1 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
}
.modal_filter_insights_by_date .content .dates {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.modal_filter_insights_by_date .content .dates input {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.modal_filter_insights_by_date .content .dates h2 {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 5px;
}
.modal_filter_insights_by_date .content .dates .date_input {
  flex: 1 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
}
.modal_filter_insights_by_date .content .buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.modal_filter_insights_by_date .content .buttons button {
  padding: 10px 20px;
  border-radius: 20px;
  color: #fff;
  margin: 0 10px;
  border: none;
}
.modal_filter_insights_by_date .content .buttons button.danger {
  background-color: #fff;
  color: #e83e8c;
  border: 1px solid #e83e8c;
}
.modal_filter_insights_by_date .content .buttons button.success {
  background-color: #e83e8c;
  color: #fff;
}/*# sourceMappingURL=style.css.map */
.sort_column {
  display: flex;
}
.sort_column .MuiButtonBase-root {
  min-width: 30px;
}
.sort_column img {
  cursor: pointer;
}
.sort_column .fa-arrow-down {
  margin-left: 10px;
  font-size: #292c55;
  display: inline-block;
}/*# sourceMappingURL=style.css.map */
.ecran_detail_campagne {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_detail_campagne .right_content {
  transition: all ease 0.3s;
}
.ecran_detail_campagne .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_detail_campagne .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_detail_campagne .right_content_header .create_campaign {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ecran_detail_campagne .right_content_header .create_campaign button {
  background: #e83e8c;
  box-shadow: 0px 5px 11px rgba(232, 62, 140, 0.25);
  border-radius: 79px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 10px 30px;
}
.ecran_detail_campagne .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_detail_campagne .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main {
  padding: 20px 1.5%;
}
.ecran_detail_campagne .right_content_main .row {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.ecran_detail_campagne .right_content_main .row .column_1 {
  width: 27%;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px 0px 0px 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 145px;
  left: 0;
  max-height: 80vh;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar {
  width: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar-track {
  background: lightgrey;
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar-thumb {
  background: #e83e8c;
  border-radius: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar-thumb:hover {
  background: violet;
}
.ecran_detail_campagne .right_content_main .row .column_1 .column_header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .column_header img {
  cursor: pointer;
}
.ecran_detail_campagne .right_content_main .row .column_1 .column_header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 116% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #292c55;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .meta_title {
  background: #fff4f9;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 133% */
  color: #292c55;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 9;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .meta_title img {
  margin-right: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing {
  padding: 10px 15px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign {
  border: 1px solid #e83e8c;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign.selected {
  border: 2px solid #e83e8c;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign.selected .listing_item_title {
  background-color: #e83e8c;
  color: #fff;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign:last-child {
  margin-bottom: 0;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_title {
  background: #fff4f9;
  border-bottom: 1px solid #e83e8c;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 233% */
  color: #000000;
  text-align: center;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content {
  display: grid;
  grid-template-columns: repeat(2, 2fr) repeat(2, 1fr);
  grid-gap: 0.2rem;
  gap: 0.2rem;
  padding: 10px 5px;
  align-items: end;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value .gr {
  color: #4cc937;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value .rd {
  color: #e74c3c;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 200% */
  color: #000000;
}
@media (max-width: 1366px) {
  .ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p {
    margin-right: 5px;
  }
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p .gr {
  color: #4cc937;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p .rd {
  color: #e74c3c;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content > div h4 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #292c55;
  opacity: 0.75;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .item_campaign_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .item_campaign_actions_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2px;
  display: inline-block;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  background: rgba(232, 62, 140, 0.13);
  opacity: 0.5;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .item_campaign_actions_container img {
  margin: 0 5px;
  width: 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 {
  width: 71.5%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee {
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content {
  padding: 15px 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content .dfe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content .dfe button {
  border: 1px solid #e83e8c;
  border-radius: 32px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 11.5px;
  /* identical to box height, or 243% */
  color: #292c55;
  padding: 5px 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content .dfe button i {
  color: #e83e8c;
  font-size: 20px;
  margin-left: 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 25px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li {
  margin: 15px 0;
  position: relative;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li:before {
  content: "";
  background-color: #e83e8c;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li p a {
  text-decoration: underline;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee header {
  padding: 20px 30px;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee header .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites {
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .sorting-options {
  padding: 10px;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .sorting-options .sorting-arrow {
  padding: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content {
  padding: 1.5% 1.5% 0;
  display: grid;
  grid-template-columns: repeat(3, 32%); /* Three columns with equal width */
  grid-column-gap: 2%;
  column-gap: 2%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign {
  padding-bottom: 5%; /* Set the percentage-based row gap */
  align-content: start;
  background: #ffffff;
  border-radius: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_title {
  background-color: #fff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* or 185% */
  display: flex;
  margin: 0px auto;
  letter-spacing: 0.15px;
  color: #292c55;
  max-width: 100%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_title h3 {
  max-width: 97%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .main_image {
  object-fit: contain;
  max-width: 100%;
  max-height: calc(var(--container-width) * 0.31);
  display: flex;
  margin: 0px auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_campaign_wrapper {
  padding: 2%;
  background: #fff4f9;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .toggle_span {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .toggle_span h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value {
  display: flex;
  align-items: center;
  align-content: flex-end;
  margin-bottom: 7px;
}
@media (max-width: 1366px) {
  .ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value {
    margin-top: 0;
  }
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* or 200% */
  text-align: center;
  align-content: flex-end;
  color: #000000;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value p .gr {
  color: #4cc937;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign h4 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  word-wrap: break-word;
  color: #292c55;
  text-align: center;
  margin-bottom: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_campaign_content {
  display: grid;
  grid-template-columns: 28% 28% 18% 18%;
  align-content: flex-end;
  grid-column-gap: 3%;
  column-gap: 3%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_campaign_content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .additionnal_items {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  align-content: flex-end;
  grid-column-gap: 3%;
  column-gap: 3%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .additionnal_items > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .item_campaign_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .item_campaign_actions_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2px;
  display: inline-block;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  background: rgba(232, 62, 140, 0.13);
  opacity: 0.5;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .item_campaign_actions_container img {
  margin: 0 5px;
  width: 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites header {
  padding: 20px 30px;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites header .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .expand-button {
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px; /* Adjust height as needed */
  width: 100%;
  cursor: pointer;
  background-color: rgba(252, 232, 241, 0.397);
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .expand-button:hover {
  background-color: rgba(248, 200, 222, 0.603);
}
.ecran_detail_campagne .right_content_main .row .column_2 .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets {
  width: 45%;
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
@media (max-width: 1366px) {
  .ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences,
  .ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets {
    width: 42%;
  }
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences h6,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  padding: 20px;
  text-transform: uppercase;
  text-align: center;
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul {
  padding: 20px;
  list-style: none;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .right,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .left,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .right,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .left {
  width: 50%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .right,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .right {
  display: flex;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  padding-left: 20px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .right .chart,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .right .chart {
  margin-right: 5px;
  background-color: #e83e8c;
  height: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .left,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .left {
  display: flex;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  word-break: break-all;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .left span,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .left span {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #292c55;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #ffffff;
}

.select-border-red {
  background-color: #f1f1f1;
  border: 1px solid #e83e8c;
  border-radius: 5px;
  padding: 5px;
}
.select-border-red select,
.select-border-red option {
  border: none;
  background-color: transparent;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #292c55;
}
.select-border-red select {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("/down-arrow.png");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  background-size: 15px;
}

/*# sourceMappingURL=style.css.map */

.ecran_creation_campagne_confirmation {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_creation_campagne_confirmation .right_content {
  transition: all ease 0.3s;
}
.ecran_creation_campagne_confirmation .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_creation_campagne_confirmation .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_creation_campagne_confirmation .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_creation_campagne_confirmation .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_main {
  padding: 20px 40px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  width: 80%; /*was 90% in updatecreas*/
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container {
  position: relative;
  cursor: pointer;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container:hover .infobulle {
  opacity: 1;
  visibility: visible;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container .infobulle {
  position: absolute;
  background-color: #e83e8c;
  padding: 10px;
  border-radius: 4px;
  width: 300px;
  top: 150%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container .infobulle:after {
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #e83e8c;
  border-left: 5px solid transparent;
  position: absolute;
  bottom: 100%;
  left: 15px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container .infobulle p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #fff;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td span.round {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e83e8c;
  margin-right: 30px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second {
  padding: 10px;
  margin: 100px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .wrapper {
  background: #fff4f9;
  padding: 1px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .wrapper .header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px;
  margin-bottom: 5px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .wrapper .header h3 {
  color: #e83e63;
  font-size: 17px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  margin: 0px;
  padding: 0px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne .number_see .number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: #000;
  margin-right: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne .number_see i {
  color: #e83e8c;
  font-size: 20px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  text-align: center;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring {
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  padding-bottom: 30px;
  padding-top: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .ad {
  margin-right: 20px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .monitor, .ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .adset {
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .monitor p, .ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .adset p {
  margin-right: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .monitor input[type=checkbox], .ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .adset input[type=checkbox] {
  width: 20px;
  height: 20px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe button {
  padding: 5px 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0px 10px;
  border: none;
  /* or 133% */
  cursor: pointer;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe button.update {
  background-color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe button.save {
  background-color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row button {
  padding: 5px 20px;
  border: 1px solid #e83e8c;
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row .left {
  display: flex;
  align-items: center;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row .left img {
  margin-left: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group .css-574lq2-MuiFormControl-root {
  margin-top: 0;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: block;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}

.toggle_span {
  text-align: right;
}

.listing_item_campaign_content {
  background: #fff4f9;
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-template-rows: repeat(1fr);
  padding: 5px 0;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
@media (max-width: 1366px) {
  .listing_item_campaign_content {
    padding: 5px 5px;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

/*# sourceMappingURL=style.css.map */

.screen_creation_package_centre_interet {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_creation_package_centre_interet .right_content {
  transition: all ease 0.3s;
}
.screen_creation_package_centre_interet .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_creation_package_centre_interet .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_creation_package_centre_interet .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_creation_package_centre_interet .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_creation_package_centre_interet .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_centre_interet .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_centre_interet .right_content_main {
  padding: 20px 40px;
}
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .client,
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .client_title,
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_centre_interet .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.screen_creation_package_centre_interet .right_content_main .sections .section.section_centre_interet .input_group_autocomplete input {
  border-bottom: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .mode_disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(167, 167, 167, 0.5);
  z-index: 99;
  cursor: not-allowed;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_currency {
  margin-left: 5px;
  padding-left: 5px;
  border-left: 0.5px solid rgba(0, 0, 0, 0.4) !important;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .container_currency_input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .container_currency_input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px;
  margin-right: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border-bottom: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group select {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group .css-oarg8b-MuiAutocomplete-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group input {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group input,
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group input option,
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .inputError {
  border-bottom: 1px solid red;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .messageError {
  font-size: 12px;
  color: red;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content {
  padding: 20px;
  background-color: #fff;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add {
  margin-top: 20px;
  padding-top: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add .fe button i {
  margin-right: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 5px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-2m9kme-MuiFormControl-root,
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root,
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .left > * {
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .right {
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .right .row {
  margin-bottom: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .row > * {
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section:first-child {
  margin: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.screen_creation_package_centre_interet .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_creation_package_centre_interet .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.screen_creation_package_centre_interet .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_centre_interet .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */
.screen_creation_package_ciblage_demographique {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_creation_package_ciblage_demographique .right_content {
  transition: all ease 0.3s;
}
.screen_creation_package_ciblage_demographique .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_creation_package_ciblage_demographique .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_creation_package_ciblage_demographique .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_ciblage_demographique .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_ciblage_demographique .right_content_main {
  padding: 20px 40px;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element {
  margin-bottom: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #e83e8c;
  border-radius: 25px;
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded {
  position: relative;
  overflow: hidden;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded > * {
  position: relative;
  z-index: 1;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded:after {
  background-color: #e5e5f7;
  opacity: 0.3;
  background: repeating-linear-gradient(45deg, #000, #000 2px, #e5e5f7 2px, #e5e5f7 10px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  content: "";
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded input {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element .unit {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 1px 2px rgba(232, 62, 140, 0.27);
  border-radius: 2px;
  width: 40px;
  height: 30px;
  outline: none;
  margin-right: 2px;
  padding-left: 5px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element:last-child {
  margin-right: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element_title {
  color: #e83e8c;
  margin-right: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* or 200% */
  letter-spacing: 0.15px;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element i {
  color: #e83e8c;
  font-size: 13px;
  margin-left: 20px;
}
.screen_creation_package_ciblage_demographique .right_content_main .title_zones_geo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .title_zones_geo i {
  cursor: pointer;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container {
  display: flex;
  justify-content: space-between;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input {
  width: 16%;
  margin: 0 15px;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input:first-child {
  margin: 0 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input:first-child input, .screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input:nth-child(2) input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 1px 2px rgba(232, 62, 140, 0.27);
  border-radius: 2px;
  border-bottom: none !important;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input.input_3 {
  width: 35%;
}
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .client,
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .client_title,
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .label_age_genre {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input {
  width: 100%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  border-bottom: none;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input option,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .inputError {
  border-bottom: 1px solid red;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .messageError {
  font-size: 12px;
  color: red;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content {
  padding: 20px;
  background-color: #fff;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add_audience .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add_audience .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add_audience .fe button i {
  margin-right: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add .fe button i {
  margin-right: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .css-2m9kme-MuiFormControl-root,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .left > * {
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .right {
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .right .row {
  margin-bottom: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .row > * {
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section:first-child {
  margin: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_ciblage_demographique .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */
.message_not_connected_fb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.message_not_connected_fb .content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.message_not_connected_fb .content h2 {
  text-align: center;
  margin-top: 20px;
}
.message_not_connected_fb .content h2 a {
  color: #000;
}/*# sourceMappingURL=style.css.map */
.error_message {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error_message__content {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error_message__content .title {
  font-size: 20px;
  font-weight: normal;
}
.error_message__content button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  /* or 160% */
  padding: 10px 30px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
  margin-top: 20px;
}/*# sourceMappingURL=style.css.map */
.creation_package_pub_add_image_screen {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_package_pub_add_image_screen .right_content {
  transition: all ease 0.3s;
}
.creation_package_pub_add_image_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_package_pub_add_image_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_package_pub_add_image_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_add_image_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_add_image_screen .right_content_main {
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale {
  padding: 50px;
  width: 80%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image input[type=file] {
  visibility: hidden;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .importer_image_btn {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  min-width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .importer_image_btn img {
  width: 80px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .ressources {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item {
  width: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image_title {
  display: flex;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
  padding: 0 10px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image_title span {
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image {
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image input {
  visibility: hidden;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image .img_container {
  height: 200px;
  background: rgba(239, 239, 239, 0.62);
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image .img_container img {
  width: auto;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image img,
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image video {
  width: 100%;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags {
  margin-top: 20px;
  width: 100%;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 0px 5px;
  margin: 3px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag:first-child {
  margin-left: 0;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag i {
  color: #e83e8c;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag p {
  margin-right: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 10px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search {
  border-bottom: 1px solid #000;
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 50px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image button {
  padding: 10px 30px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */
@charset "UTF-8";
.creation_carousel_screen {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_carousel_screen .right_content {
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_carousel_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_header .back_title .back {
  cursor: pointer;
}
.creation_carousel_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_main {
  padding: 20px 40px;
}
.creation_carousel_screen .right_content_main .main_section_carousel {
  background-color: #fff;
  padding: 30px 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .image_title,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .image_title .title,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .image_title .title {
  width: 80%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .image,
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .video,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .image,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .video {
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item {
  position: relative;
  overflow: hidden;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item video {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions button:last-child {
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item {
  position: relative;
  overflow: hidden;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions button:last-child {
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .main_section_carousel .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__header i {
  color: #292c55;
  font-size: 25px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left {
  width: 66%;
  padding-left: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default {
  padding-bottom: 30px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .checks {
  margin-top: 50px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 20px;
  background-color: #e83e8c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
  font-family: "Rubik";
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span:hover::after {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span.carousel_video::after {
  content: "Sur le fil d'actualit\E9   facebook, le carousel sera diffus\E9   en tant que vid\E9o si cela am\E9liore la performance";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span.ordre_visuel::after {
  content: "Les visuels qui performent le mieux seront montr\E9s en premier sur certains placements";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span::after {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e83e8c;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  line-height: 20px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line label {
  display: flex;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  /* identical to box height, or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line input {
  margin-right: 10px;
  accent-color: #e83e8c;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 20px;
  background-color: #e83e8c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
  font-family: "Rubik";
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span:hover::after {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.titre_defaut::after {
  content: "Si aucun titre n'est ajout\E9   \E0   l'un de vos visuels, c'est ce titre qui sera utilis\E9";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.description_defaut::after {
  content: "Si aucune description n'est ajout\E9  e \E0   l'un de vos visuels, c'est cette description qui sera utilis\E9  e";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.lien_defaut::after {
  content: "Si aucun lien n'est ajout\E9   \E0   l'un de vos visuels, c'est ce lien qui sera utilis\E9";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.cta_defaut::after {
  content: "Si aucun call to action n'est ajout\E9   \E0   l'un de vos visuels, c'est ce call to action qui sera utilis\E9";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span::after {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e83e8c;
  border-radius: 5px;
  padding: 5px;
  width: 300px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .MuiAutocomplete-root {
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right {
  width: 30%;
  padding-right: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable {
  max-height: 60vh;
  overflow-y: scroll;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  top: 100px;
  left: 0;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar {
  width: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar-track {
  background: lightgrey;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar-thumb {
  background: #e83e8c;
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar-thumb:hover {
  background: violet;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right input,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right select {
  border: none;
  border-bottom: 1px solid rgba(232, 62, 140, 0.6);
  padding: 10px;
  outline: none;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .block {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text {
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  padding: 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text .inline {
  display: flex;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text .inline > * {
  width: 48%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text select {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.5);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text input {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.5) !important;
  background-color: #fff !important;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 650px;
  left: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container button {
  padding: 10px 30px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  color: #fff;
  letter-spacing: 0.15px;
  background: rgba(41, 44, 85, 0.25);
  border: none;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container button.active, .creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container button.cancel {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container .cancel {
  padding: 10px 30px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  color: #e83e8c;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  background: rgba(41, 44, 85, 0.25);
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container .cancel.active, .creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container .cancel.cancel {
  background-color: #fff;
}
@media screen and (min-width: 1900px) {
  .creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container {
    top: 800px;
  }
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre {
  margin-bottom: 15px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre.default .element .center {
  height: 155px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element > div {
  height: 150px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element img:not(.default) {
  height: 150px;
  object-fit: cover;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element video {
  height: 150px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element video {
  height: 600px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .title,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .title {
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  /* or 225% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #e83e8c;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element {
  height: 150px;
  background: rgba(41, 44, 85, 0.06);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element .center,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  height: 100%;
  margin: 0;
  left: unset;
  position: static;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element.element_story,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element.element_story {
  height: 600px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element h5,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 85, 0.7);
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 50px 0;
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr .circle, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr img, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search .fa-search, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search input, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search input:placeholder, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .tags p, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .left, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item_right, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags .tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags .tag i, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags .tag p, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .add_tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .add_tag input, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item.add_image_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 32%;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .left, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item_right, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags .tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags .tag i, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags .tag p, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .add_tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .add_tag input, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item.selected .image_title, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item.selected .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item.add_image_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .image video, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .image video {
  height: 220px;
  object-fit: cover;
  width: 220px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .pagination, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .df {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.centre_interet select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.objectif select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group input,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group input option,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content {
  padding: 5px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add .fe button i {
  margin-right: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .css-2m9kme-MuiFormControl-root,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .left > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .right {
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .row > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section:first-child {
  margin: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .row {
  display: flex;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .row.row_1 .left {
  width: 65%;
  transition: all ease 0.1s;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs {
  margin-bottom: 50px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input[type=text] {
  font-size: 16px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::placeholder {
  font-size: 16px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input {
  width: 400px;
  outline: none;
  display: block;
  margin: 20px 0;
  padding: 5px 5px 5px 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input.MuiInputBase-input {
  padding: 0;
  border-bottom: none;
  margin: 10px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .row.row_1 .right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.5s;
}
.creation_carousel_screen .right_content_main .row.row_1 .right .pxt {
  padding-left: 10px;
  padding-right: 10px;
}
.creation_carousel_screen .right_content_main .row.row_1 .right .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #858585;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .client_advanced_mode .client,
.creation_carousel_screen .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .client_advanced_mode .client_title,
.creation_carousel_screen .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.creation_carousel_screen .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search,
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .list_carousels {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .list_carousels .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  margin-left: 15px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text {
  margin-bottom: 20px;
  width: 30%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text input,
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  flex: 1 1;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text input::placeholder,
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text {
  margin-bottom: 20px;
  width: 30%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text input,
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  flex: 1 1;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text input::placeholder,
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  width: 48%;
  margin-bottom: 20px;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected .tags {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected h5 {
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected h5 {
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr, .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr .circle, .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img, .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search .fa-search, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input:placeholder, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags p, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title .title, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title .title {
  width: 80%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image {
  position: relative;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .left, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item_right, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag i, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag p, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.add_image_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-left: 10px;
  margin-right: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .left, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item_right, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag i, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag p, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.add_image_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image video, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image video {
  width: 100%;
  height: 290px;
  object-fit: cover;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .pagination, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section .input_group select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section .input_group input,
.creation_carousel_screen .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.creation_carousel_screen .right_content_main .sections .section .input_group input option,
.creation_carousel_screen .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.creation_carousel_screen .right_content_main .sections .section_content {
  padding: 20px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.creation_carousel_screen .right_content_main .sections .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.creation_carousel_screen .right_content_main .sections .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.creation_carousel_screen .right_content_main .sections .section_content .add .fe button i {
  margin-right: 10px;
}
.creation_carousel_screen .right_content_main .sections .section_content .css-2m9kme-MuiFormControl-root,
.creation_carousel_screen .right_content_main .sections .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .left > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .right {
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .sections .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section_content .row > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section:first-child {
  margin: 0;
}
.creation_carousel_screen .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .d-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .d-sb button {
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .d-sb .standard {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
}
.creation_carousel_screen .right_content_main .d-sb .outline {
  padding: 10px 20px;
  border-radius: 25px;
  color: #e83e8c;
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .d-sb .outline:hover {
  background-color: #e83e8c;
  color: #ffffff;
}
.creation_carousel_screen .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.creation_carousel_screen .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */
.creation_post_screen {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_post_screen .right_content {
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_post_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_post_screen .right_content_header .back_title .back {
  cursor: pointer;
}
.creation_post_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_post_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_main {
  padding: 20px 40px;
}
.creation_post_screen .right_content_main .main_section_add_post {
  background-color: #fff;
  padding: 30px 20px;
}
.creation_post_screen .right_content_main .main_section_add_post .category_post {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  display: flex;
  width: 250px;
}
.creation_post_screen .right_content_main .main_section_add_post .category_post .facebook,
.creation_post_screen .right_content_main .main_section_add_post .category_post .instagram {
  cursor: pointer;
  flex: 1 1;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  padding: 5px 20px;
  border-radius: 5px;
  color: #292c55;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .category_post .facebook.active,
.creation_post_screen .right_content_main .main_section_add_post .category_post .instagram.active {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  background: rgba(232, 62, 140, 0.1);
}
.creation_post_screen .right_content_main .main_section_add_post .list_posts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.creation_post_screen .right_content_main .main_section_add_post .list_posts .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  cursor: pointer;
}
.creation_post_screen .right_content_main .main_section_add_post .pagination {
  display: flex;
  justify-content: center;
}
.creation_post_screen .right_content_main .main_section_add_post .pagination .Mui-selected {
  background-color: #292c55;
  color: #fff;
}
.creation_post_screen .right_content_main .main_section_add_post .actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.creation_post_screen .right_content_main .main_section_add_post .actions button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* or 160% */
  padding: 10px 50px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item .image_title,
.creation_post_screen .right_content_main .main_section_add_post .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item .image_title .title,
.creation_post_screen .right_content_main .main_section_add_post .image_item .image_title .title {
  width: 80%;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item .image,
.creation_post_screen .right_content_main .main_section_add_post .video_item .video,
.creation_post_screen .right_content_main .main_section_add_post .image_item .image,
.creation_post_screen .right_content_main .main_section_add_post .image_item .video {
  position: relative;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item {
  position: relative;
  overflow: hidden;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item video {
  width: 100%;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions button:last-child {
  background-color: #292c55;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item {
  position: relative;
  overflow: hidden;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions button:last-child {
  background-color: #292c55;
}
.creation_post_screen .right_content_main .main_section_add_post .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.creation_post_screen .right_content_main .main_section_add_post .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.creation_post_screen .right_content_main .main_section_add_post .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_main .main_section_add_post .main__header i {
  color: #292c55;
  font-size: 25px;
  cursor: pointer;
}
.creation_post_screen .right_content_main .main_section_add_post .main__content {
  padding: 40px 20px;
}
.creation_post_screen .right_content_main .row {
  display: flex;
  justify-content: space-between;
}
.creation_post_screen .right_content_main .row.row_1 .left {
  width: 65%;
  transition: all ease 0.1s;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs {
  margin-bottom: 50px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input[type=text] {
  font-size: 16px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::placeholder {
  font-size: 16px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input {
  width: 400px;
  outline: none;
  display: block;
  margin: 20px 0;
  padding: 5px 5px 5px 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .creation_post_screen .right_content_main .row.row_1 .left .title_inputs input.MuiInputBase-input {
  padding: 0;
  border-bottom: none;
  margin: 10px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_post_screen .right_content_main .row.row_1 .right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.5s;
}
.creation_post_screen .right_content_main .row.row_1 .right .pxt {
  padding-left: 10px;
  padding-right: 10px;
}
.creation_post_screen .right_content_main .row.row_1 .right .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #858585;
  margin-bottom: 20px;
}
.creation_post_screen .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_post_screen .right_content_main .client_advanced_mode .client,
.creation_post_screen .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.creation_post_screen .right_content_main .client_advanced_mode .client_title,
.creation_post_screen .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_main .d-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_post_screen .right_content_main .d-sb button {
  cursor: pointer;
}
.creation_post_screen .right_content_main .d-sb .standard {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
}
.creation_post_screen .right_content_main .d-sb .outline {
  padding: 10px 20px;
  border-radius: 25px;
  color: #e83e8c;
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .d-sb .outline:hover {
  background-color: #e83e8c;
  color: #ffffff;
}
.creation_post_screen .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.creation_post_screen .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */
.ecran_priorisation_evenments {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_priorisation_evenments .right_content {
  transition: all ease 0.3s;
}
.ecran_priorisation_evenments .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_priorisation_evenments .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_priorisation_evenments .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_priorisation_evenments .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_priorisation_evenments .right_content_main {
  padding: 20px 40px;
}
.ecran_priorisation_evenments .right_content_main .main__content .main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin: 50px 0;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #fff;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header .left {
  display: flex;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header .left p {
  margin-right: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header .left select {
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table_content_header button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 5px 20px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head {
  display: grid;
  grid-column-gap: 2rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  background: rgba(232, 62, 140, 0.03);
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
  position: relative;
  display: flex;
  align-items: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p:last-child {
  display: flex;
  justify-content: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
  background-color: #e83e8c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: #fff;
  font-size: 10px;
  font-family: "Rubik";
  position: relative;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span:hover::after {
  visibility: visible;
  opacity: 1;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span.priority_defaut::after {
  content: "priority_defaut";
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span.event_defaut::after {
  content: "event_defaut";
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p span::after {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e83e8c;
  border-radius: 5px;
  padding: 5px;
  width: 300px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  text-align: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .head p:not(:first-child) {
  text-align: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body {
  padding-bottom: 50px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  grid-column-gap: 2rem;
  column-gap: 2rem;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .MuiAutocomplete-inputRoot::before {
  border-bottom: 1px solid #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .MuiSvgIcon-root {
  fill: #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .actions {
  display: flex;
  padding-left: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .body .item .actions i {
  color: #e83e8c;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  padding-bottom: 50px;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  grid-column-gap: 2rem;
  column-gap: 2rem;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .MuiAutocomplete-inputRoot::before {
  border-bottom: 1px solid #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .MuiSvgIcon-root {
  fill: #e83e8c;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .actions {
  display: flex;
  justify-content: center;
}
.ecran_priorisation_evenments .right_content_main .main__content .table_content .table .add_event .item .actions i {
  color: #e83e8c;
  font-size: 20px;
}
.ecran_priorisation_evenments .right_content_main .main__content .dfe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 100px;
}
.ecran_priorisation_evenments .right_content_main .main__content .dfe button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #e83e8c;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

/*# sourceMappingURL=style.css.map */

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.steps .step.active p {
  opacity: 1;
}
.steps .step a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from the parent element */
}
.steps .step a:hover, .steps .step a:visited, .steps .step a:active {
  color: inherit; /* Ensure the color remains consistent in all states */
  text-decoration: none; /* Ensure underline does not appear in any state */
}
.steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.steps > * {
  margin-left: 5px;
  margin-right: 5px;
}

/*# sourceMappingURL=style.css.map */

.ecran_configuration_monitoring {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_configuration_monitoring .right_content {
  transition: all ease 0.3s;
}
.ecran_configuration_monitoring .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_configuration_monitoring .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_configuration_monitoring .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main {
  padding: 20px 40px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: flex-end;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row_select_account {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row_select_account select {
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row_select_account p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  padding-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .row button {
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  padding: 7px 40px;
  border: none;
  border-radius: 20px;
  background-color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group {
  margin-bottom: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group .css-574lq2-MuiFormControl-root {
  margin-top: 0;
  margin-left: 0;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: block;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  margin-right: 30px;
  overflow: hidden;
  border-radius: 5px;
  width: 75%;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .dfe button {
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #ffffff;
  padding: 10px 40px;
  border: none;
  border-radius: 25px;
  background-color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  margin-bottom: 30px;
  background: #ffffff;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring .actions {
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring .actions button {
  background-color: #e83e8c;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .single_monitoring .actions button.outline {
  background-color: transparent;
  border: 1px solid #e83e8c;
  color: #e83e8c;
  margin-right: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application {
  padding: 20px 50px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .search_list_campaigns {
  display: flex;
  align-items: flex-end;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .search_list_campaigns .MuiAutocomplete-root {
  flex: 1 1;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .search_list_campaigns i {
  margin-right: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .app_subtitle {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .application .app_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.7;
  margin-bottom: 10px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .left {
  display: flex;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add input {
  margin-left: 10px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .right {
  display: flex;
  align-items: center;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .right h4 {
  margin-right: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .search_add .right select {
  min-width: 300px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  table-layout: fixed;
  position: relative;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table .add_btn_table {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #342f32;
  border: 1px solid #342f32;
  background-color: transparent;
  padding: 2px 10px;
  border-radius: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr td:first-child {
  padding-left: 50px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr td:last-child {
  padding-left: 60px;
  padding-right: 60px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody select {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 5px;
  outline: none;
  color: #000;
  background-color: #fff;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody select option {
  color: #000;
  font-weight: bold;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  left: 0;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box {
  padding: 20px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: #fff6fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box .choix_strategie_title,
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .choix_strategie_box .historique_title {
  margin: 15px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_configuration_monitoring .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}

/*# sourceMappingURL=style.css.map */

.ecran_branchement_monitoring_campagne {
  display: flex;
  justify-content: flex-end;
  background-color: #f1f1f1;
}
.ecran_branchement_monitoring_campagne .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_branchement_monitoring_campagne .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}
.ecran_branchement_monitoring_campagne .right_content_main .buttons {
  display: flex;
  justify-content: flex-end;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  bottom: 20px; /* Adjust based on your layout */
  z-index: 100; /* Ensure it is above other content */
}
.ecran_branchement_monitoring_campagne .right_content_main .buttons button {
  padding: 10px 30px;
  background-color: #e83e8c;
  color: #fff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  margin: 0px 10px;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-radius: 25px;
  border: 1px solid transparent;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign {
  margin: 5px;
  background: rgba(41, 44, 85, 0.06);
  border: 0.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 38px;
  padding: 5px 20px;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .top .checkboxes {
  margin-top: 0;
  display: flex;
  justify-content: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .bottom {
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign.hachuree {
  background-color: #aaa;
  color: #eee;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign.hachuree .bottom .campaign_name {
  color: #fff;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign.hachuree .adsets label {
  color: #fff;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .fa-edit {
  margin-right: 10px;
  margin-left: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .fas {
  font-size: 15px;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .campaign_name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .campaign_name .bold {
  font-weight: 700;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .autocomplete_button {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .autocomplete_button .add {
  margin-left: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: #ffffff;
  padding: 5px 10px;
  background-color: #e83e8c;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .autocomplete_button li .auto {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div input[type=checkbox] {
  width: 17px;
  height: 17px;
  accent-color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-left: 5px;
  display: inline-block;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div:last-child {
  margin-right: 0;
}
.ecran_branchement_monitoring_campagne .right_content_main .campaign_option {
  background: rgba(41, 44, 85, 0.06);
  margin: 5px;
  padding: 5px 10px;
  border-radius: 25px;
}
.ecran_branchement_monitoring_campagne .right_content_main .campaign_option p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: flex-start;
  margin-top: 60px;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account .input_btn {
  display: flex;
  align-items: flex-end;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account .input_btn button {
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 5px 30px;
  border: none;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid #e83e8c;
  margin-left: 50px;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account select {
  margin-top: 20px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 20px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie {
  margin-top: 60px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 0px;
  width: 950px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn button {
  border-radius: 144px;
  padding: 5px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border: 1px solid #e83e8c;
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe.dfe_2 {
  margin-top: 60px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  border: none;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  flex: 1 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th:first-child {
  padding-left: 80px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody tr td .line {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody tr td:first-child {
  padding-left: 50px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row {
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row > * {
  margin: 0 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}

/*# sourceMappingURL=style.css.map */

.fenetre_avertissement_monitoring_campagne {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fenetre_avertissement_monitoring_campagne .content {
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
}
.fenetre_avertissement_monitoring_campagne .content .title {
  color: #292c55;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
}
.fenetre_avertissement_monitoring_campagne .content .buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.fenetre_avertissement_monitoring_campagne .content .buttons button {
  padding: 10px 30px;
  border-radius: 20px;
  margin: 0 10px;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  cursor: pointer;
}
.fenetre_avertissement_monitoring_campagne .content .buttons button.submit {
  background-color: #e83e8c;
  color: #fff;
  border: 1px solid transparent;
}
.fenetre_avertissement_monitoring_campagne .content .buttons button.cancel {
  background-color: transparent;
  color: #e83e8c;
  border: 1px solid #e83e8c;
}/*# sourceMappingURL=style.css.map */
.screen_alerts {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_alerts .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_alerts .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_alerts .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_alerts .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_alerts .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main {
  padding: 20px 40px;
}
.screen_alerts .right_content_main .dfe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
}
.screen_alerts .right_content_main .dfe button {
  background-color: #e83e8c;
  border-radius: 25px;
  border: none;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections {
  margin-top: 50px;
}
.screen_alerts .right_content_main .sections .section {
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.screen_alerts .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.screen_alerts .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content .selecteur_compte select {
  border: none;
  border-bottom: 1px solid #000;
  padding: 5px;
  min-width: 200px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table {
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td {
  background: rgba(41, 44, 85, 0.08);
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td select, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td select {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px;
  background-color: transparent;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input[type=date], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=date] {
  width: 100px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input[type=number], .screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input[type=text], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=number], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=text] {
  width: 70px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td p, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td p {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td .fa-check-circle, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td .fa-check-circle {
  font-size: 22px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td .actions, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td .actions i, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions i {
  margin: 0 7px;
  color: #e83e8c;
  font-size: 18px;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td.pause_limite, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td.pause_limite {
  text-align: center;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td input[type=checkbox], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td p, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table th, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table th {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  border: 0.5px solid rgba(232, 62, 140, 0.46);
  border-collapse: collapse;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td input, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td input {
  outline: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .autocomplete, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .autocomplete {
  border: 1px solid rgba(41, 44, 85, 0.08);
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiInputBase-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiOutlinedInput-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiInputBase-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiOutlinedInput-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 5px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-2m9kme-MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1869usk-MuiFormControl-root, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-2m9kme-MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1869usk-MuiFormControl-root {
  width: 180px;
  margin: 0;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-fullWidth,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiTextField-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-fullWidth,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiTextField-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-fullWidth .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-fullWidth .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-fullWidth .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-fullWidth .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags {
  margin: 0px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags .tag, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags .tag {
  border: 1px solid #e83e8c;
  color: #fff;
  border-radius: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags .tag i, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags .tag i {
  color: #e83e8c;
  font-size: 14px;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags .tag p, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags .tag p {
  color: #e83e8c;
  font-size: 12px;
  margin-right: 10px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .selecteur_compte_button, .screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .selecteur_compte_button button, .screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button button {
  padding: 5px 10px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  background-color: #fff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.15px;
  color: #e83e8c;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table {
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td {
  padding: 20px 10px;
  background: rgba(41, 44, 85, 0.08);
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td select {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td select {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px;
  background-color: transparent;
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px;
  background-color: transparent;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=date] {
  width: 100px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=number], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=text] {
  width: 70px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td p {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td .fa-check-circle {
  font-size: 22px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions i {
  margin: 0 7px;
  color: #e83e8c;
  font-size: 18px;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td.pause_limite {
  text-align: center;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .red {
  color: #e70d0d;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .green {
  color: #4cc937;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .campagnes .campagne {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .campagnes .campagne p {
  margin-right: 10px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table th {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  border: 0.5px solid rgba(232, 62, 140, 0.46);
  border-collapse: collapse;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button button {
  padding: 5px 10px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  background-color: #fff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.15px;
  color: #e83e8c;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table {
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table .row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table .row p {
  width: 45%;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td {
  padding: 10px;
  width: 50%;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td.pause_limite {
  text-align: center;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .red {
  color: #e70d0d;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .green {
  color: #4cc937;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .campagnes .campagne {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .campagnes .campagne p {
  margin-right: 10px;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table th {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
  padding: 10px;
  width: 50%;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table,
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td {
  border: 0.5px solid rgba(232, 62, 140, 0.46);
  border-collapse: collapse;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .selecteur_compte_button {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .selecteur_compte_button button {
  padding: 5px 10px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  background-color: #fff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.15px;
  color: #e83e8c;
  cursor: pointer;
}/*# sourceMappingURL=style.css.map */
.screen_selectionner_publicites_existantes {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_selectionner_publicites_existantes .right_content {
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_selectionner_publicites_existantes .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main {
  padding: 20px 40px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section {
  background-color: #fff;
  padding: 30px 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .image_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .image_title .title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .image_title .title {
  width: 80%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .image,
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .video,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .image,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .video {
  position: relative;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item {
  position: relative;
  overflow: hidden;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item video {
  width: 100%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions button:last-child {
  background-color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item {
  position: relative;
  overflow: hidden;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions button:last-child {
  background-color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__header i {
  color: #292c55;
  font-size: 25px;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content {
  padding: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .dfe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .dfe button.disabled {
  background-color: #ccc;
  color: #fff;
  cursor: not-allowed;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .empty_result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple {
  border: 1px solid transparent;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected {
  border: 1px solid #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .publicite_existante_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .publicite_existante_title {
  background-color: #e83e8c;
  color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .publicite_existante_title a,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .publicite_existante_title a {
  color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .screenshot_carousel header.entete_fcb,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .screenshot_carousel header.entete_fcb {
  background-color: #e83e8c !important;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .screenshot_carousel header.entete_fcb .entete_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .screenshot_carousel header.entete_fcb .entete_title {
  color: #fff !important;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel {
  width: 25%;
  background: #ffffff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .main_title_format {
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .main_title_format.title_format_story {
  margin-top: 50px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel {
    flex-direction: column;
    align-items: center;
  }
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel .title_slide_outside {
  margin-bottom: 5px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel {
    margin: 20px 0;
  }
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel .main_img {
  width: 100%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot {
  position: relative;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 3px;
  margin-bottom: 0;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb {
  padding: 8px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  outline: none;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected {
  background-color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected .entete_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected i {
  color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input {
  border-color: white;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons img,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons i {
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .entete_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .image img {
  width: 25px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h5,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right {
  display: flex;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right i {
  font-size: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right .fa-times {
  margin-left: 5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper {
  padding-bottom: 10px;
  padding-right: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text {
  padding: 10px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text h5 {
  font-size: 10px;
  font-weight: 900;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text a,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text button {
  background-color: #d8dadf;
  color: #000;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 7px;
  border: none;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  text-align: center;
  display: inline-block;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper-slide {
  width: 80% !important;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .elements_supplementaires {
  position: absolute;
  bottom: 12%;
  right: 30px;
  color: #fff;
  font-size: 20px;
  text-shadow: 1px 1px #000;
  font-family: arial;
  display: none;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .main_img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook video {
  width: 100%;
  height: 160px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple {
  width: 20%;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding-bottom: 20px;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .image_container {
  display: flex;
  justify-content: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple img {
  width: auto;
  max-width: 100%;
  margin-top: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: space-around;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .interactions p {
  display: flex;
  align-items: center;
  font-size: 8px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 8px;
  color: #333;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .publicite_existante_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* or 400% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .publicite_existante_title .link_preview {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* or 400% */
  letter-spacing: 0.15px;
  color: #aaa;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets {
  display: flex;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .comptes_ads {
  margin-right: 50px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .comptes_ads select,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .adsets select {
  border: none;
  border-bottom: 1px solid #000;
  padding: 10px;
  min-width: 150px;
  background-color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .comptes_ads .MuiAutocomplete-root,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .adsets .MuiAutocomplete-root {
  background-color: #fff;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_choisir_compte {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_choisir_compte .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_choisir_compte .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_choisir_compte .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_choisir_compte .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_choisir_compte .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_choisir_compte .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_choisir_compte .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_choisir_compte .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_choisir_compte .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_choisir_compte .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_choisir_compte .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content {
  width: 68%;
  padding: 50px 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta {
  width: 500px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta header {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta header img {
  width: 80px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta header h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta .form_control {
  margin-top: 10px;
  margin-bottom: 100px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta .MuiOutlinedInput-notchedOutline,
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .choixcompteads_meta .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #000000;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .meta_tiktok {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 100px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .meta_tiktok img {
  margin: 0 20px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation {
  margin-top: 50px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button {
  margin: 0 50px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button {
  border-radius: 144px;
  padding: 10px 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button.plain {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  color: #ffffff;
  background: #e83e8c;
}
.ecran_campagne_choisir_compte .right_content_main .choix_reseau_social_content .navigation button.outline {
  border: 1px solid #e83e8c;
  color: #e83e8c;
}/*# sourceMappingURL=style.css.map */
.screen_choose_page_select_ad_page .icon_search_box {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.screen_choose_page_select_ad_page .icon_search_box i {
  color: #e83e8c;
}
.screen_choose_page_select_ad_page .icon_search_box input {
  background-color: transparent;
  border: none;
  flex: 1 1;
  padding: 10px;
  margin-left: 5px;
  outline: none;
}
.screen_choose_page_select_ad_page .liste_intersect_client {
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 280px;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar {
  width: 10px;
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar-track {
  background: lightgrey;
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar-thumb {
  background: #e83e8c;
  border-radius: 5px;
}
.screen_choose_page_select_ad_page .liste_intersect_client::-webkit-scrollbar-thumb:hover {
  background: violet;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item:hover, .screen_choose_page_select_ad_page .liste_intersect_client .list_item.selected {
  background-color: #f1f1f1;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left {
  display: flex;
  align-items: center;
  width: 70%;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left .account {
  display: flex;
  align-items: center;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left .account p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .left .account img {
  margin-right: 10px;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .right button {
  padding: 10px;
  border-radius: 25px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #292c55;
  background-color: transparent;
  border: 1px solid #292c55;
  cursor: pointer;
  transition: all ease 0.3s;
}
.screen_choose_page_select_ad_page .liste_intersect_client .list_item .right button.active {
  background-color: #292c55;
  color: #fff;
}/*# sourceMappingURL=style.css.map */
.ecran_campagne_comptes_actifs {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_comptes_actifs .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_comptes_actifs .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_comptes_actifs .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_comptes_actifs .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_comptes_actifs .right_content_main .btn_primary_account {
  padding: 5px 20px;
  background-color: #292c55;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #fff;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
.ecran_campagne_comptes_actifs .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_comptes_actifs .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_comptes_actifs .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_comptes_actifs .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie {
  margin-top: 60px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 0px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn .row {
  display: flex;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn .row button {
  margin: 0 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn button {
  border-radius: 144px;
  padding: 5px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border: 1px solid #e83e8c;
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe.dfe_2 {
  margin-top: 60px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  border: none;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  flex: 1 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th:first-child {
  padding-left: 60px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody tr td:first-child {
  padding-left: 50px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row {
  display: flex;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row > * {
  margin: 0 10px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_comptes_actifs .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}/*# sourceMappingURL=style.css.map */
