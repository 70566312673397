.fenetre_avertissement_monitoring_campagne {
  @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fenetre_avertissement_monitoring_campagne .content {
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
}
.fenetre_avertissement_monitoring_campagne .content .title {
  color: #292c55;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
}
.fenetre_avertissement_monitoring_campagne .content .buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.fenetre_avertissement_monitoring_campagne .content .buttons button {
  padding: 10px 30px;
  border-radius: 20px;
  margin: 0 10px;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  cursor: pointer;
}
.fenetre_avertissement_monitoring_campagne .content .buttons button.submit {
  background-color: #e83e8c;
  color: #fff;
  border: 1px solid transparent;
}
.fenetre_avertissement_monitoring_campagne .content .buttons button.cancel {
  background-color: transparent;
  color: #e83e8c;
  border: 1px solid #e83e8c;
}/*# sourceMappingURL=style.css.map */