.error_message {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error_message__content {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error_message__content .title {
  font-size: 20px;
  font-weight: normal;
}
.error_message__content button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  /* or 160% */
  padding: 10px 30px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
  margin-top: 20px;
}/*# sourceMappingURL=style.css.map */