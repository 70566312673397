.render_geodemo_option {
  display: flex;
  align-items: center;
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
}
.render_geodemo_option .circle {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
.render_geodemo_option p {
  color: #000;
  margin-right: 10px;
  font-family: "Lato", sans-serif;
}/*# sourceMappingURL=style.css.map */