.sort_column {
  display: flex;
}
.sort_column .MuiButtonBase-root {
  min-width: 30px;
}
.sort_column img {
  cursor: pointer;
}
.sort_column .fa-arrow-down {
  margin-left: 10px;
  font-size: #292c55;
  display: inline-block;
}/*# sourceMappingURL=style.css.map */