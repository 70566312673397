.facebook_screenshot {
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 250px;
  margin-bottom: 50px;
  cursor: pointer;
}
.facebook_screenshot .subattachments {
  display: flex;
  position: relative;
}
.facebook_screenshot .subattachments img {
  width: 33.32%;
  border: 1px solid #fff;
}
.facebook_screenshot .subattachments img.w-50 {
  width: 50%;
  height: 125px;
  -o-object-fit: cover;
     object-fit: cover;
}
.facebook_screenshot .subattachments .nbr_subattachments_left {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  width: 100%;
  font-size: 20px;
}
.facebook_screenshot .main_img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
.facebook_screenshot .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.facebook_screenshot .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.facebook_screenshot .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
}
.facebook_screenshot header.entete_fcb {
  padding: 8px 0;
  background-color: #3498db;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.facebook_screenshot header.entete_fcb.selected {
  background-color: #e83e8c;
  color: #fff;
}
.facebook_screenshot header.entete_fcb .entete_title {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.facebook_screenshot .account .left .image img {
  width: 25px;
}
.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.facebook_screenshot .account .left .text h5,
.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.facebook_screenshot .account .right {
  display: flex;
  flex-direction: row !important;
  width: auto !important;
}
.facebook_screenshot .account .right .fa-times {
  margin-left: 10px;
}
.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.facebook_screenshot .main_img {
  width: 100%;
}
.facebook_screenshot .main_video {
  width: 100%;
}
.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -3px;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
  font-weight: bold;
}
.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
}
.facebook_screenshot .commentaires_partages {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.facebook_screenshot .commentaires_partages p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  /* identical to box height, or 121% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.facebook_screenshot .commentaires_partages .droite {
  display: flex;
  align-items: center;
}
.facebook_screenshot .commentaires_partages .droite p:last-child {
  margin-left: 5px;
}
.facebook_screenshot .commentaires_partages .gauche {
  display: flex;
  align-items: center;
}
.facebook_screenshot .commentaires_partages .gauche p {
  margin-left: 5px;
}
.facebook_screenshot .commentaires_partages .gauche .like,
.facebook_screenshot .commentaires_partages .gauche .love {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebook_screenshot .commentaires_partages .gauche .like i,
.facebook_screenshot .commentaires_partages .gauche .love i {
  color: #fff;
  font-size: 7px;
}
.facebook_screenshot .commentaires_partages .gauche .like {
  background-color: #3498db;
}
.facebook_screenshot .commentaires_partages .gauche .love {
  background-color: #e74c3c;
}/*# sourceMappingURL=style.css.map */