@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.creation_package_pub_add_image_screen {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_package_pub_add_image_screen .right_content {
  transition: all ease 0.3s;
}
.creation_package_pub_add_image_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_package_pub_add_image_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_package_pub_add_image_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_add_image_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_add_image_screen .right_content_main {
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale {
  padding: 50px;
  width: 80%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image input[type=file] {
  visibility: hidden;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .importer_image_btn {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  min-width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .importer_image_btn img {
  width: 80px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .ressources {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item {
  width: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image_title {
  display: flex;
  align-items: center;
  background: #ffffff;
  justify-content: space-between;
  padding: 0 10px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image_title span {
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image {
  cursor: pointer;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image input {
  visibility: hidden;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image .img_container {
  height: 200px;
  background: rgba(239, 239, 239, 0.62);
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image .img_container img {
  width: auto;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image img,
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .image_item .image video {
  width: 100%;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags {
  margin-top: 20px;
  width: 100%;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 0px 5px;
  margin: 3px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag:first-child {
  margin-left: 0;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag i {
  color: #e83e8c;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags .liste .tag p {
  margin-right: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .tags h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 10px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search {
  border-bottom: 1px solid #000;
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 50px;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_add_image_screen .right_content_main .contenu_principale .importer_image button {
  padding: 10px 30px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */