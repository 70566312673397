.creation_post_screen {
  @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_post_screen .right_content {
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_post_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_post_screen .right_content_header .back_title .back {
  cursor: pointer;
}
.creation_post_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_post_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_main {
  padding: 20px 40px;
}
.creation_post_screen .right_content_main .main_section_add_post {
  background-color: #fff;
  padding: 30px 20px;
}
.creation_post_screen .right_content_main .main_section_add_post .category_post {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  display: flex;
  width: 250px;
}
.creation_post_screen .right_content_main .main_section_add_post .category_post .facebook,
.creation_post_screen .right_content_main .main_section_add_post .category_post .instagram {
  cursor: pointer;
  flex: 1;
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  padding: 5px 20px;
  border-radius: 5px;
  color: #292c55;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .category_post .facebook.active,
.creation_post_screen .right_content_main .main_section_add_post .category_post .instagram.active {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  background: rgba(232, 62, 140, 0.1);
}
.creation_post_screen .right_content_main .main_section_add_post .list_posts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.creation_post_screen .right_content_main .main_section_add_post .list_posts .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  cursor: pointer;
}
.creation_post_screen .right_content_main .main_section_add_post .pagination {
  display: flex;
  justify-content: center;
}
.creation_post_screen .right_content_main .main_section_add_post .pagination .Mui-selected {
  background-color: #292c55;
  color: #fff;
}
.creation_post_screen .right_content_main .main_section_add_post .actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.creation_post_screen .right_content_main .main_section_add_post .actions button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* or 160% */
  padding: 10px 50px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item .image_title,
.creation_post_screen .right_content_main .main_section_add_post .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item .image_title .title,
.creation_post_screen .right_content_main .main_section_add_post .image_item .image_title .title {
  width: 80%;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item .image,
.creation_post_screen .right_content_main .main_section_add_post .video_item .video,
.creation_post_screen .right_content_main .main_section_add_post .image_item .image,
.creation_post_screen .right_content_main .main_section_add_post .image_item .video {
  position: relative;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item {
  position: relative;
  overflow: hidden;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item video {
  width: 100%;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_post_screen .right_content_main .main_section_add_post .video_item_actions button:last-child {
  background-color: #292c55;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item {
  position: relative;
  overflow: hidden;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_post_screen .right_content_main .main_section_add_post .image_item_actions button:last-child {
  background-color: #292c55;
}
.creation_post_screen .right_content_main .main_section_add_post .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.creation_post_screen .right_content_main .main_section_add_post .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.creation_post_screen .right_content_main .main_section_add_post .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_main .main_section_add_post .main__header i {
  color: #292c55;
  font-size: 25px;
  cursor: pointer;
}
.creation_post_screen .right_content_main .main_section_add_post .main__content {
  padding: 40px 20px;
}
.creation_post_screen .right_content_main .row {
  display: flex;
  justify-content: space-between;
}
.creation_post_screen .right_content_main .row.row_1 .left {
  width: 65%;
  transition: all ease 0.1s;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs {
  margin-bottom: 50px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input[type=text] {
  font-size: 16px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::-moz-placeholder {
  font-size: 16px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::placeholder {
  font-size: 16px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input {
  width: 400px;
  outline: none;
  display: block;
  margin: 20px 0;
  padding: 5px 5px 5px 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .creation_post_screen .right_content_main .row.row_1 .left .title_inputs input.MuiInputBase-input {
  padding: 0;
  border-bottom: none;
  margin: 10px;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_post_screen .right_content_main .row.row_1 .left .title_inputs input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_post_screen .right_content_main .row.row_1 .right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.5s;
}
.creation_post_screen .right_content_main .row.row_1 .right .pxt {
  padding-left: 10px;
  padding-right: 10px;
}
.creation_post_screen .right_content_main .row.row_1 .right .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #858585;
  margin-bottom: 20px;
}
.creation_post_screen .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_post_screen .right_content_main .client_advanced_mode .client,
.creation_post_screen .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.creation_post_screen .right_content_main .client_advanced_mode .client_title,
.creation_post_screen .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_post_screen .right_content_main .d-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_post_screen .right_content_main .d-sb button {
  cursor: pointer;
}
.creation_post_screen .right_content_main .d-sb .standard {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
}
.creation_post_screen .right_content_main .d-sb .outline {
  padding: 10px 20px;
  border-radius: 25px;
  color: #e83e8c;
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  transition: all ease 0.3s;
}
.creation_post_screen .right_content_main .d-sb .outline:hover {
  background-color: #e83e8c;
  color: #ffffff;
}
.creation_post_screen .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.creation_post_screen .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */