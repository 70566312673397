@charset "UTF-8";
.creation_carousel_screen {
  @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_carousel_screen .right_content {
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_carousel_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_header .back_title .back {
  cursor: pointer;
}
.creation_carousel_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_main {
  padding: 20px 40px;
}
.creation_carousel_screen .right_content_main .main_section_carousel {
  background-color: #fff;
  padding: 30px 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .image_title,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .image_title .title,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .image_title .title {
  width: 80%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .image,
.creation_carousel_screen .right_content_main .main_section_carousel .video_item .video,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .image,
.creation_carousel_screen .right_content_main .main_section_carousel .image_item .video {
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item {
  position: relative;
  overflow: hidden;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item video {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .video_item_actions button:last-child {
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item {
  position: relative;
  overflow: hidden;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .image_item_actions button:last-child {
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .main_section_carousel .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__header i {
  color: #292c55;
  font-size: 25px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left {
  width: 66%;
  padding-left: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default {
  padding-bottom: 30px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .checks {
  margin-top: 50px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 20px;
  background-color: #e83e8c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
  font-family: "Rubik";
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span:hover::after {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span.carousel_video::after {
  content: "Sur le fil d'actualité facebook, le carousel sera diffusé en tant que vidéo si cela améliore la performance";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span.ordre_visuel::after {
  content: "Les visuels qui performent le mieux seront montrés en premier sur certains placements";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line span::after {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e83e8c;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  line-height: 20px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line label {
  display: flex;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  /* identical to box height, or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .check_line input {
  margin-right: 10px;
  accent-color: #e83e8c;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 20px;
  background-color: #e83e8c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
  font-family: "Rubik";
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span:hover::after {
  visibility: visible;
  opacity: 1;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.titre_defaut::after {
  content: "Si aucun titre n'est ajouté à l'un de vos visuels, c'est ce titre qui sera utilisé";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.description_defaut::after {
  content: "Si aucune description n'est ajoutée à l'un de vos visuels, c'est cette description qui sera utilisée";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.lien_defaut::after {
  content: "Si aucun lien n'est ajouté à l'un de vos visuels, c'est ce lien qui sera utilisé";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span.cta_defaut::after {
  content: "Si aucun call to action n'est ajouté à l'un de vos visuels, c'est ce call to action qui sera utilisé";
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default p span::after {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #e83e8c;
  border-radius: 5px;
  padding: 5px;
  width: 300px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_left .contenu_par_default .MuiAutocomplete-root {
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right {
  width: 30%;
  padding-right: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable {
  max-height: 60vh;
  overflow-y: scroll;
  position: sticky;
  background-color: #fff;
  top: 100px;
  left: 0;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar {
  width: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar-track {
  background: lightgrey;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar-thumb {
  background: #e83e8c;
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .scrollable::-webkit-scrollbar-thumb:hover {
  background: violet;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right input,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right select {
  border: none;
  border-bottom: 1px solid rgba(232, 62, 140, 0.6);
  padding: 10px;
  outline: none;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .block {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text {
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  padding: 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text .inline {
  display: flex;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text .inline > * {
  width: 48%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text select {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.5);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text input {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.5) !important;
  background-color: #fff !important;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text input::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .text input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 650px;
  left: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container button {
  padding: 10px 30px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  color: #fff;
  letter-spacing: 0.15px;
  background: rgba(41, 44, 85, 0.25);
  border: none;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container button.active, .creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container button.cancel {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container .cancel {
  padding: 10px 30px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  color: #e83e8c;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  background: rgba(41, 44, 85, 0.25);
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container .cancel.active, .creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container .cancel.cancel {
  background-color: #fff;
}
@media screen and (min-width: 1900px) {
  .creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .button_container {
    top: 800px;
  }
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre {
  margin-bottom: 15px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre.default .element .center {
  height: 155px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element > div {
  height: 150px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element img:not(.default) {
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element video {
  height: 150px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element video {
  height: 600px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .title,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .title {
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  /* or 225% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #e83e8c;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element {
  height: 150px;
  background: rgba(41, 44, 85, 0.06);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element .center,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  height: 100%;
  margin: 0;
  left: unset;
  position: static;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element.element_story,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element.element_story {
  height: 600px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .carre .element h5,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content_right .story .element h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 85, 0.7);
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 50px 0;
  position: relative;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr .circle, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr img, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search .fa-search, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search input, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search input:placeholder, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .tags p, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .search_tags .tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .left, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item_right, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags .tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags .tag i, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .tags .tag p, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .add_tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .add_tag input, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item.add_image_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 32%;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .left, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item_right, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags .tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags .tag i, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .tags .tag p, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .add_tag, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .add_tag input, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item.selected .image_title, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item.selected .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item.add_image_item, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .image_title h4, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .list_images .image_item .image video, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .list_images .image_item .image video {
  height: 220px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 220px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_image .section_content .pagination, .creation_carousel_screen .right_content_main .main_section_carousel .main__content .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .df {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.centre_interet select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group.objectif select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group input,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group input option,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content {
  padding: 5px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .add .fe button i {
  margin-right: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .css-2m9kme-MuiFormControl-root,
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .left > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .right {
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_content .row > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section:first-child {
  margin: 0;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .main_section_carousel .main__content .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .row {
  display: flex;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .row.row_1 .left {
  width: 65%;
  transition: all ease 0.1s;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs {
  margin-bottom: 50px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input[type=text] {
  font-size: 16px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::-moz-placeholder {
  font-size: 16px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::placeholder {
  font-size: 16px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input {
  width: 400px;
  outline: none;
  display: block;
  margin: 20px 0;
  padding: 5px 5px 5px 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input.MuiInputBase-input {
  padding: 0;
  border-bottom: none;
  margin: 10px;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .row.row_1 .left .title_inputs input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .row.row_1 .right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.5s;
}
.creation_carousel_screen .right_content_main .row.row_1 .right .pxt {
  padding-left: 10px;
  padding-right: 10px;
}
.creation_carousel_screen .right_content_main .row.row_1 .right .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #858585;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .client_advanced_mode .client,
.creation_carousel_screen .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .client_advanced_mode .client_title,
.creation_carousel_screen .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_carousel_screen .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.creation_carousel_screen .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .search,
.creation_carousel_screen .right_content_main .sections .section.section_carousel .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .list_carousels {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section.section_carousel .list_carousels .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  margin-left: 15px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text {
  margin-bottom: 20px;
  width: 30%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text input,
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  flex: 1;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text input::-moz-placeholder, .creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text textarea::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text input::placeholder,
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text {
  margin-bottom: 20px;
  width: 30%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text input,
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  flex: 1;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text input::-moz-placeholder, .creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text input::placeholder,
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  width: 48%;
  margin-bottom: 20px;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected .tags {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected h5 {
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected h5 {
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr, .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr .circle, .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img, .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .creation_carousel_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search .fa-search, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input:placeholder, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags p, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .search,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title .title, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title .title,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title .title {
  width: 80%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image {
  position: relative;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .left, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item_right, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag i, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag p, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.add_image_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-left: 10px;
  margin-right: 10px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .left, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item_right, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag i, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag p, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected {
  background-color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.add_image_item, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title h4, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image video, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image img,
.creation_carousel_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image video {
  width: 100%;
  height: 290px;
  -o-object-fit: cover;
     object-fit: cover;
}
.creation_carousel_screen .right_content_main .sections .section.section_image .section_content .pagination, .creation_carousel_screen .right_content_main .sections .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section .input_group select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.creation_carousel_screen .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.creation_carousel_screen .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section .input_group input,
.creation_carousel_screen .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.creation_carousel_screen .right_content_main .sections .section .input_group input option,
.creation_carousel_screen .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.creation_carousel_screen .right_content_main .sections .section_content {
  padding: 20px;
  background-color: #fff;
}
.creation_carousel_screen .right_content_main .sections .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.creation_carousel_screen .right_content_main .sections .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.creation_carousel_screen .right_content_main .sections .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.creation_carousel_screen .right_content_main .sections .section_content .add .fe button i {
  margin-right: 10px;
}
.creation_carousel_screen .right_content_main .sections .section_content .css-2m9kme-MuiFormControl-root,
.creation_carousel_screen .right_content_main .sections .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .left > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .right {
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.creation_carousel_screen .right_content_main .sections .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_carousel_screen .right_content_main .sections .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.creation_carousel_screen .right_content_main .sections .section_content .row > * {
  width: 45%;
}
.creation_carousel_screen .right_content_main .sections .section:first-child {
  margin: 0;
}
.creation_carousel_screen .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.creation_carousel_screen .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.creation_carousel_screen .right_content_main .sections .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_carousel_screen .right_content_main .d-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_carousel_screen .right_content_main .d-sb button {
  cursor: pointer;
}
.creation_carousel_screen .right_content_main .d-sb .standard {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
}
.creation_carousel_screen .right_content_main .d-sb .outline {
  padding: 10px 20px;
  border-radius: 25px;
  color: #e83e8c;
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  transition: all ease 0.3s;
}
.creation_carousel_screen .right_content_main .d-sb .outline:hover {
  background-color: #e83e8c;
  color: #ffffff;
}
.creation_carousel_screen .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.creation_carousel_screen .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */