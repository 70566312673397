.filtres {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f1f1f1;
  position: sticky;
  top: 80px;
  z-index: 1000;
}
.filtres .border_rd {
  border: 1px solid #e83e8c;
  border-radius: 5px;
  padding: 5px;
}
.filtres .filter_date {
  margin-right: 20px;
  min-width: 150px;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #e83e8c;
  background-color: transparent;
  outline: none;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  background-image: url("/down-arrow.png");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 15px;
}
.filtres .creer_par input,
.filtres .creer_par select,
.filtres .creer_par option,
.filtres .compte_client input,
.filtres .compte_client select,
.filtres .compte_client option {
  outline: none;
  border: none;
  background-color: transparent;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.filtres .creer_par select,
.filtres .compte_client select {
  width: 150px;
  padding-bottom: 5px;
  position: relative;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  background-image: url("/down-arrow.png");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  background-size: 15px;
}
.filtres .date_debut {
  margin-right: 20px;
}
.filtres .date_fin {
  margin-right: 30px;
  transition: all ease 0.3s;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes shakeInput {
  0% {
    transform: translate(0, 0);
  }
  10%, 90% {
    transform: translate(-5px, 0);
  }
  20%, 80% {
    transform: translate(5px, 0);
  }
  30%, 50%, 70% {
    transform: translate(-5px, 0);
  }
  40%, 60% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.filtres .date {
  display: flex;
  align-items: center;
  position: relative;
  width: 150px;
  height: 30px;
}
.filtres .date img {
  margin-right: 5px;
  position: absolute;
  right: 10px;
  z-index: 9;
}
.filtres .date input {
  border: none;
  outline: none;
  background-color: transparent;
  margin-left: 10px;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  padding: 5px;
}
.filtres .date input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 20px;
  width: 100%;
  z-index: 999999;
  cursor: pointer;
}
.filtres .creer_par {
  margin-right: 20px;
}
.filtres p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  /* identical to box height, or 187% */
  display: flex;
  align-items: center;
  color: #000000;
  margin-right: 20px;
}/*# sourceMappingURL=style.css.map */