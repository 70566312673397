@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.ecran_branchement_monitoring_campagne {
  display: flex;
  justify-content: flex-end;
  background-color: #f1f1f1;
}
.ecran_branchement_monitoring_campagne .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_branchement_monitoring_campagne .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}
.ecran_branchement_monitoring_campagne .right_content_main .buttons {
  display: flex;
  justify-content: flex-end;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  bottom: 20px; /* Adjust based on your layout */
  z-index: 100; /* Ensure it is above other content */
}
.ecran_branchement_monitoring_campagne .right_content_main .buttons button {
  padding: 10px 30px;
  background-color: #e83e8c;
  color: #fff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  margin: 0px 10px;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-radius: 25px;
  border: 1px solid transparent;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign {
  margin: 5px;
  background: rgba(41, 44, 85, 0.06);
  border: 0.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 38px;
  padding: 5px 20px;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .top .checkboxes {
  margin-top: 0;
  display: flex;
  justify-content: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .bottom {
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign.hachuree {
  background-color: #aaa;
  color: #eee;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign.hachuree .bottom .campaign_name {
  color: #fff;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign.hachuree .adsets label {
  color: #fff;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .fa-edit {
  margin-right: 10px;
  margin-left: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .fas {
  font-size: 15px;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .campaign_name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .strategy_campaigns .campaign .campaign_name .bold {
  font-weight: 700;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .autocomplete_button {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .autocomplete_button .add {
  margin-left: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: #ffffff;
  padding: 5px 10px;
  background-color: #e83e8c;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .autocomplete_button li .auto {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div input[type=checkbox] {
  width: 17px;
  height: 17px;
  accent-color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-left: 5px;
  display: inline-block;
}
.ecran_branchement_monitoring_campagne .right_content_main .list_campaigns .checkboxes > div:last-child {
  margin-right: 0;
}
.ecran_branchement_monitoring_campagne .right_content_main .campaign_option {
  background: rgba(41, 44, 85, 0.06);
  margin: 5px;
  padding: 5px 10px;
  border-radius: 25px;
}
.ecran_branchement_monitoring_campagne .right_content_main .campaign_option p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: flex-start;
  margin-top: 60px;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account .input_btn {
  display: flex;
  align-items: flex-end;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account .input_btn button {
  cursor: pointer;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 5px 30px;
  border: none;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid #e83e8c;
  margin-left: 50px;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account select {
  margin-top: 20px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.ecran_branchement_monitoring_campagne .right_content_main .row_select_account p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 20px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie {
  margin-top: 60px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 0px;
  width: 950px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_title_addbtn button {
  border-radius: 144px;
  padding: 5px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border: 1px solid #e83e8c;
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe.dfe_2 {
  margin-top: 60px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser .dfe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  border: none;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  flex: 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th:first-child {
  padding-left: 80px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody tr td .line {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tbody tr td:first-child {
  padding-left: 50px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row {
  display: flex;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .row > * {
  margin: 0 10px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_branchement_monitoring_campagne .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}

/*# sourceMappingURL=style.css.map */
