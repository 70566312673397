@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.creation_package_pub_screen {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.creation_package_pub_screen .right_content {
  transition: all ease 0.3s;
}
.creation_package_pub_screen .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.creation_package_pub_screen .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_header .back_title .back {
  cursor: pointer;
}
.creation_package_pub_screen .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_main {
  padding: 20px 40px;
}
.creation_package_pub_screen .right_content_main .wrapper_compte_cat_pub {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .choix_compte_publicitaire {
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .choix_compte_publicitaire p {
  margin-right: 20px;
  color: #000;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}
.creation_package_pub_screen .right_content_main .choix_compte_publicitaire select {
  padding: 5px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.creation_package_pub_screen .right_content_main .cata_pub {
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .cata_pub p {
  margin-right: 20px;
  color: #000;
  font-size: 16px;
  font-family: "Lato", sans-serif;
}
.creation_package_pub_screen .right_content_main .row {
  display: flex;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left {
  width: 65%;
  transition: all ease 0.1s;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs {
  margin-bottom: 50px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input[type=text] {
  font-size: 16px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::-moz-placeholder {
  font-size: 16px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input[type=text]::placeholder {
  font-size: 16px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input {
  width: 400px;
  outline: none;
  display: block;
  margin: 20px 0;
  padding: 5px 5px 5px 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input.MuiInputBase-input {
  padding: 0;
  border-bottom: none;
  margin: 10px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .title_inputs input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_catalog {
  flex: 1;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_catalog label {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_product {
  flex: 1;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .catalog_product .input_product label {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .checkbox_container {
  margin: 2rem 0;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .checkbox_container .text_label {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .row.row_1 .left .checkbox_container .checkbox_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
  gap: 70px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.5s;
}
.creation_package_pub_screen .right_content_main .row.row_1 .right .pxt {
  padding-left: 10px;
  padding-right: 10px;
}
.creation_package_pub_screen .right_content_main .row.row_1 .right .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #858585;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .client_advanced_mode .client,
.creation_package_pub_screen .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .client_advanced_mode .client_title,
.creation_package_pub_screen .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.creation_package_pub_screen .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.creation_package_pub_screen .right_content_main .sections .section.section_publicites_existantes .section_header .nbr {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes {
  padding: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes {
  display: flex;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .add_publicite_existante {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin: 10px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante {
  margin: 10px;
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags {
  margin: 5px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags .tag {
  border: 1px solid #e83e8c;
  color: #fff;
  border-radius: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags .tag i {
  color: #e83e8c;
  font-size: 14px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .tags .tag p {
  color: #e83e8c;
  font-size: 10px;
  margin-right: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected {
  border: 1px solid #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected .delete_pub {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected .publicite_existante_title,
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante.selected a {
  background-color: #e83e8c;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .delete_pub {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .image_container {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-top: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante img {
  max-width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: space-around;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .interactions p {
  display: flex;
  align-items: center;
  font-size: 8px;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 8px;
  color: #333;
}
.creation_package_pub_screen .right_content_main .sections .section .section_content_publicites_existantes .liste_publicites_existantes .publicite_existante .publicite_existante_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  /* or 400% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .list_carousels {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .list_carousels .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  margin-left: 15px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .list_posts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .list_posts .add_carousel {
  border: 1.5px solid rgba(41, 44, 85, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  min-height: 340px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .pagination {
  display: flex;
  justify-content: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .pagination .Mui-selected {
  background-color: #292c55;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .wrapper .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text {
  margin-bottom: 20px;
  width: 75%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text i {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text input,
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #292c55;
  flex: 1;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text input::-moz-placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text textarea::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text input::placeholder,
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected p.main_para {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .inputError {
  border-bottom: 1px solid red;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .messageError {
  font-size: 12px;
  color: red;
}
.creation_package_pub_screen .right_content_main .sections .section.section_description .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .wrapper .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text {
  margin-bottom: 20px;
  width: 75%;
  border-bottom: 1px solid #e83e8c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text i {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text input,
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  flex: 1;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text input::-moz-placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea::-moz-placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text input::placeholder,
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .add_text textarea::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  width: 48%;
  margin-bottom: 20px;
  transition: all ease 0.3s;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected p.main_para {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected .tags {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body.selected h5 {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input {
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_bodies .body .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 30px 20px 10px 10px;
  position: relative;
  transition: all ease 0.3s;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected h5 {
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text.selected .tags {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text p.main_para {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-bottom: 40px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  border-radius: 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 3px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 150px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_texte .section_content .list_texts .text .fa-times.close {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_carousel .nbr, .creation_package_pub_screen .right_content_main .sections .section.section_posts_sponsorisees .nbr {
  margin-left: 20px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr .circle, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .creation_package_pub_screen .right_content_main .sections .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search .fa-search, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search input:placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags p, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .search_tags .tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .search,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image_title .title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title .title,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image_title .title {
  width: 80%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .image_item .image, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .image_item .image {
  position: relative;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .left, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item_right, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag i, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .tags .tag p, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected .image_title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item.add_image_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_videos .video_item .image video, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-left: 10px;
  margin-right: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .left, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item_right, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag i, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .tags .tag p, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected .image_title, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected .image_title {
  background-color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.selected .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item.add_image_item, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 260px;
  height: 310px !important;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image_title h4, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .list_images .image_item .image video, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image img,
.creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .list_images .image_item .image video {
  width: 260px;
  height: 260px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}
.creation_package_pub_screen .right_content_main .sections .section.section_image .section_content .pagination, .creation_package_pub_screen .right_content_main .sections .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group select {
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group input,
.creation_package_pub_screen .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.creation_package_pub_screen .right_content_main .sections .section .input_group input option,
.creation_package_pub_screen .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.creation_package_pub_screen .right_content_main .sections .section_content {
  padding: 20px;
  background-color: #fff;
}
.creation_package_pub_screen .right_content_main .sections .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.creation_package_pub_screen .right_content_main .sections .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.creation_package_pub_screen .right_content_main .sections .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.creation_package_pub_screen .right_content_main .sections .section_content .add .fe button i {
  margin-right: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section_content .css-2m9kme-MuiFormControl-root,
.creation_package_pub_screen .right_content_main .sections .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .left > * {
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .right {
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.creation_package_pub_screen .right_content_main .sections .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.creation_package_pub_screen .right_content_main .sections .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.creation_package_pub_screen .right_content_main .sections .section_content .row > * {
  width: 45%;
}
.creation_package_pub_screen .right_content_main .sections .section:first-child {
  margin: 0;
}
.creation_package_pub_screen .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.creation_package_pub_screen .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .sections .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.creation_package_pub_screen .right_content_main .d-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creation_package_pub_screen .right_content_main .d-sb button {
  cursor: pointer;
}
.creation_package_pub_screen .right_content_main .d-sb .standard {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
}
.creation_package_pub_screen .right_content_main .d-sb .outline {
  padding: 10px 20px;
  border-radius: 25px;
  color: #e83e8c;
  background-color: transparent;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #e83e8c;
  letter-spacing: 0.15px;
  transition: all ease 0.3s;
}
.creation_package_pub_screen .right_content_main .d-sb .outline:hover {
  background-color: #e83e8c;
  color: #ffffff;
}
.creation_package_pub_screen .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.creation_package_pub_screen .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}

.creation_package_pub_screen .right_content_main .sections .section_texte .section_header_title .nbr,
.creation_package_pub_screen .right_content_main .sections .section_description .section_header_title .nbr {
  height: 30px;
  width: 30px;
}

.section_appairer_des_visuels {
  position: fixed;
  z-index: 9999;
  top: 70px;
  bottom: 0px;
  right: 0px;
  width: 80%;
  background-color: #fff;
  padding: 30px 20px;
  overflow-y: scroll;
  max-height: 100%;
}
.section_appairer_des_visuels .video_item .image_title,
.section_appairer_des_visuels .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.section_appairer_des_visuels .video_item .image_title .title,
.section_appairer_des_visuels .image_item .image_title .title {
  width: 80%;
}
.section_appairer_des_visuels .video_item .image,
.section_appairer_des_visuels .video_item .video,
.section_appairer_des_visuels .image_item .image,
.section_appairer_des_visuels .image_item .video {
  position: relative;
}
.section_appairer_des_visuels .video_item {
  position: relative;
  overflow: hidden;
}
.section_appairer_des_visuels .video_item video {
  width: 100%;
}
.section_appairer_des_visuels .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.section_appairer_des_visuels .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .video_item_actions button:last-child {
  background-color: #292c55;
}
.section_appairer_des_visuels .image_item {
  position: relative;
  overflow: hidden;
}
.section_appairer_des_visuels .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.section_appairer_des_visuels .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .image_item_actions button:last-child {
  background-color: #292c55;
}
.section_appairer_des_visuels .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.section_appairer_des_visuels .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.section_appairer_des_visuels .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.section_appairer_des_visuels .main__header i {
  color: #292c55;
  font-size: 25px;
}
.section_appairer_des_visuels .main__content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content_left {
  width: 66%;
  padding-left: 20px;
}
.section_appairer_des_visuels .main__content_right {
  width: 30%;
  padding-right: 20px;
}
.section_appairer_des_visuels .main__content_right .button_container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.section_appairer_des_visuels .main__content_right .button_container button {
  padding: 10px 50px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  color: #fff;
  letter-spacing: 0.15px;
  background: rgba(41, 44, 85, 0.25);
  border: none;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content_right .button_container button.active {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .main__content_right .carre {
  margin-bottom: 50px;
}
.section_appairer_des_visuels .main__content_right .carre .element > div {
  min-height: 332px;
}
.section_appairer_des_visuels .main__content_right .carre .element img:not(.default) {
  min-height: 332px;
  -o-object-fit: cover;
     object-fit: cover;
}
.section_appairer_des_visuels .main__content_right .carre .element video {
  min-height: 332px;
}
.section_appairer_des_visuels .main__content_right .story .element video {
  min-height: 600px;
}
.section_appairer_des_visuels .main__content_right .carre .title,
.section_appairer_des_visuels .main__content_right .story .title {
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;
  /* or 225% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #e83e8c;
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
}
.section_appairer_des_visuels .main__content_right .carre .element,
.section_appairer_des_visuels .main__content_right .story .element {
  min-height: 332px;
  background: rgba(41, 44, 85, 0.06);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.section_appairer_des_visuels .main__content_right .carre .element .center,
.section_appairer_des_visuels .main__content_right .story .element .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 277px;
  height: 100%;
  margin: 0;
  left: unset;
  position: static;
}
.section_appairer_des_visuels .main__content_right .carre .element.element_story,
.section_appairer_des_visuels .main__content_right .story .element.element_story {
  min-height: 600px;
}
.section_appairer_des_visuels .main__content_right .carre .element h5,
.section_appairer_des_visuels .main__content_right .story .element h5 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 85, 0.7);
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 50px 0;
  position: relative;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr, .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr {
  background-color: transparent;
  margin-left: 30px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr .circle, .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr .circle {
  background-color: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr img, .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_header .section_header_title .nbr img:nth-child(2), .section_appairer_des_visuels .main__content .section.section_video .section_header .section_header_title .nbr img:nth-child(2) {
  margin-left: -5px;
  margin-right: -5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search {
  border-bottom: 1px solid #000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search .fa-search, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search .fa-search {
  margin-right: 10px;
  color: #e83e8c;
  opacity: 0.5;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search input, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search input {
  border: none;
  outline: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search input:placeholder, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  opacity: 0.3;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .tags p, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .tags p {
  margin: 0 5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .search,
.section_appairer_des_visuels .main__content .section.section_image .section_content .search_tags .tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .search,
.section_appairer_des_visuels .main__content .section.section_video .section_content .search_tags .tags {
  display: flex;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item {
  background: rgba(234, 234, 234, 0.62);
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 30%;
  display: flex;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .left, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .left {
  width: 55%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item_right, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags .tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags .tag i, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .tags .tag p, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .add_tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .add_tag input, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .add_tag input:placeholder, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item.selected .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item.add_image_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image img,
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image video, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image img,
.section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image video {
  width: 100%;
  height: 200px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_videos .video_item .image video, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_videos .video_item .image video {
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item {
  background: rgba(234, 234, 234, 0.62);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  transition: all ease 0.3s;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .left, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .left {
  width: 55%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item_right, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 45%;
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags .tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags .tag {
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  padding: 2px 5px;
  margin: 10px 5px;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags .tag i, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags .tag i {
  color: #e83e8c;
  font-size: 12px;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .tags .tag p, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .tags .tag p {
  margin-right: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-left: 5px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  word-break: break-all;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .add_tag, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .add_tag {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  width: 100%;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .add_tag input, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .add_tag input {
  outline: none;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .add_tag input:placeholder, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .add_tag input:placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item.selected .image_title, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item.selected .image_title {
  background-color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item.selected .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item.selected .image_title h4 {
  background-color: #e83e8c;
  color: #fff;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item.add_image_item, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item.add_image_item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .image_title h4, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .image_title h4 {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: 0.15px;
  word-break: break-all;
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .image img,
.section_appairer_des_visuels .main__content .section.section_image .section_content .list_images .image_item .image video, .section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .image img,
.section_appairer_des_visuels .main__content .section.section_video .section_content .list_images .image_item .image video {
  width: 230px;
  height: 230px;
  -o-object-fit: cover;
     object-fit: cover;
}
.section_appairer_des_visuels .main__content .section.section_image .section_content .pagination, .section_appairer_des_visuels .main__content .section.section_video .section_content .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content .section .df {
  display: flex;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.section_appairer_des_visuels .main__content .section .input_group select {
  width: 100%;
}
.section_appairer_des_visuels .main__content .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.section_appairer_des_visuels .main__content .section .input_group.centre_interet select {
  width: 100%;
}
.section_appairer_des_visuels .main__content .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.section_appairer_des_visuels .main__content .section .input_group.objectif select {
  width: 100%;
}
.section_appairer_des_visuels .main__content .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section .input_group input,
.section_appairer_des_visuels .main__content .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.section_appairer_des_visuels .main__content .section .input_group input option,
.section_appairer_des_visuels .main__content .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.section_appairer_des_visuels .main__content .section_content {
  padding: 5px;
  background-color: #fff;
}
.section_appairer_des_visuels .main__content .section_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.section_appairer_des_visuels .main__content .section_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.section_appairer_des_visuels .main__content .section_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.section_appairer_des_visuels .main__content .section_content .add .fe button i {
  margin-right: 10px;
}
.section_appairer_des_visuels .main__content .section_content .css-2m9kme-MuiFormControl-root,
.section_appairer_des_visuels .main__content .section_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.section_appairer_des_visuels .main__content .section_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .left > * {
  width: 45%;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .right {
  width: 45%;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .right .row {
  margin-bottom: 0;
}
.section_appairer_des_visuels .main__content .section_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.section_appairer_des_visuels .main__content .section_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.section_appairer_des_visuels .main__content .section_content .row > * {
  width: 45%;
}
.section_appairer_des_visuels .main__content .section:first-child {
  margin: 0;
}
.section_appairer_des_visuels .main__content .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.section_appairer_des_visuels .main__content .section_header i {
  color: #e83e8c;
}
.section_appairer_des_visuels .main__content .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.section_appairer_des_visuels .main__content .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}

.blockError {
  border: 1px solid red;
}/*# sourceMappingURL=style.css.map */