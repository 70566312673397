.screen_alerts {
  @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_alerts .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_alerts .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_alerts .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_alerts .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_alerts .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main {
  padding: 20px 40px;
}
.screen_alerts .right_content_main .dfe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
}
.screen_alerts .right_content_main .dfe button {
  background-color: #e83e8c;
  border-radius: 25px;
  border: none;
  padding: 10px 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections {
  margin-top: 50px;
}
.screen_alerts .right_content_main .sections .section {
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.screen_alerts .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.screen_alerts .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_header_title .nbr {
  margin-left: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 24px;
  /* identical to box height, or 267% */
  letter-spacing: 0.15px;
  color: #ffffff;
  background-color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content .selecteur_compte select {
  border: none;
  border-bottom: 1px solid #000;
  padding: 5px;
  min-width: 200px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table {
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td {
  background: rgba(41, 44, 85, 0.08);
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td select, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td select {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px;
  background-color: transparent;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input[type=date], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=date] {
  width: 100px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input[type=number], .screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td input[type=text], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=number], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=text] {
  width: 70px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td p, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td p {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tr.new td .fa-check-circle, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td .fa-check-circle {
  font-size: 22px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td .actions, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td .actions i, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions i {
  margin: 0 7px;
  color: #e83e8c;
  font-size: 18px;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td.pause_limite, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td.pause_limite {
  text-align: center;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td input[type=checkbox], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td p, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table th, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table th {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table td, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  border: 0.5px solid rgba(232, 62, 140, 0.46);
  border-collapse: collapse;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td input, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td input {
  outline: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .autocomplete, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .autocomplete {
  border: 1px solid rgba(41, 44, 85, 0.08);
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiInputBase-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiOutlinedInput-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiInputBase-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiOutlinedInput-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 5px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-2m9kme-MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1869usk-MuiFormControl-root, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-2m9kme-MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1869usk-MuiFormControl-root {
  width: 180px;
  margin: 0;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-fullWidth,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiTextField-root,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiAutocomplete-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-fullWidth,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiTextField-root,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-fullWidth .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiFormControl-fullWidth .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-fullWidth .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiFormControl-fullWidth .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags {
  margin: 0px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags .tag, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags .tag {
  border: 1px solid #e83e8c;
  color: #fff;
  border-radius: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags .tag i, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags .tag i {
  color: #e83e8c;
  font-size: 14px;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .tableau table tbody tr td .tags .tag p, .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tbody tr td .tags .tag p {
  color: #e83e8c;
  font-size: 12px;
  margin-right: 10px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .selecteur_compte_button, .screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.screen_alerts .right_content_main .sections .section_content.controle_budget .selecteur_compte_button button, .screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button button {
  padding: 5px 10px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  background-color: #fff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.15px;
  color: #e83e8c;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table {
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td {
  padding: 20px 10px;
  background: rgba(41, 44, 85, 0.08);
  border: none;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td select {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td select {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px;
  background-color: transparent;
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px;
  background-color: transparent;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=date] {
  width: 100px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=number], .screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td input[type=text] {
  width: 70px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td p {
  color: rgba(41, 44, 85, 0.5) !important;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table tr.new td .fa-check-circle {
  font-size: 22px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .actions i {
  margin: 0 7px;
  color: #e83e8c;
  font-size: 18px;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td.pause_limite {
  text-align: center;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .red {
  color: #e70d0d;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .green {
  color: #4cc937;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .campagnes .campagne {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td .campagnes .campagne p {
  margin-right: 10px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table th {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
  padding: 10px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table,
.screen_alerts .right_content_main .sections .section_content.objectifs .tableau table td {
  border: 0.5px solid rgba(232, 62, 140, 0.46);
  border-collapse: collapse;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.screen_alerts .right_content_main .sections .section_content.objectifs .selecteur_compte_button button {
  padding: 5px 10px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  background-color: #fff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.15px;
  color: #e83e8c;
  cursor: pointer;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table {
  width: 100%;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table .row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table .row p {
  width: 45%;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td {
  padding: 10px;
  width: 50%;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td.pause_limite {
  text-align: center;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .red {
  color: #e70d0d;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .green {
  color: #4cc937;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .campagnes .campagne {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td .campagnes .campagne p {
  margin-right: 10px;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td input[type=checkbox] {
  width: 20px;
  height: 20px;
  accent-color: #e83e8c;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table th {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
  padding: 10px;
  width: 50%;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table,
.screen_alerts .right_content_main .sections .section_content.parametrages .tableau table td {
  border: 0.5px solid rgba(232, 62, 140, 0.46);
  border-collapse: collapse;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .selecteur_compte_button {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.screen_alerts .right_content_main .sections .section_content.parametrages .selecteur_compte_button button {
  padding: 5px 10px;
  border: 1px solid #e83e8c;
  border-radius: 20px;
  background-color: #fff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.15px;
  color: #e83e8c;
  cursor: pointer;
}/*# sourceMappingURL=style.css.map */