@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.choix_compte {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
}
.choix_compte .row {
  display: flex;
}
.choix_compte .row .input_group:first-child {
  margin-right: 30px;
}
.choix_compte .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-top: 10px;
  padding-bottom: 10px;
}
.choix_compte .MuiOutlinedInput-notchedOutline,
.choix_compte .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 0;
}
.choix_compte .btn {
  padding: 5px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.choix_compte .input_group {
  margin-right: 20px;
}
.choix_compte .input_group h3 {
  font-size: 18px;
  font-weight: normal;
}
.choix_compte .input_group h3 .info_bulle {
  position: relative;
  display: inline-block;
}
.choix_compte .input_group h3 .info_bulle:hover:before, .choix_compte .input_group h3 .info_bulle:hover:after {
  visibility: visible;
  opacity: 1;
}
.choix_compte .input_group h3 .info_bulle i {
  color: #e83e8c;
  margin-left: 5px;
  font-size: 25px;
}
.choix_compte .input_group h3 .info_bulle:before {
  content: "";
  position: absolute;
  top: 20px;
  left: 5px;
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 8px solid transparent;
  border-bottom: 20px solid #e83e8c;
  border-left: 12px solid transparent;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.choix_compte .input_group h3 .info_bulle:after {
  content: "Your ad will use the page name and profile picture. It will not click through to the actual page on Facebook";
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 150%;
  left: 0;
  background-color: #e83e8c;
  color: #fff;
  font-family: Rubik, sans-serif;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.choix_compte .input_group .form_control {
  width: 300px;
}/*# sourceMappingURL=style.css.map */