@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
.ecran_liste_campagnes {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_liste_campagnes .right_content {
  transition: all ease 0.3s;
}
.ecran_liste_campagnes .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 999;
}
.ecran_liste_campagnes .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_liste_campagnes .right_content_header .create_campaign {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ecran_liste_campagnes .right_content_header .create_campaign button {
  background: #e83e8c;
  box-shadow: 0px 5px 11px rgba(232, 62, 140, 0.25);
  border-radius: 79px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main {
  padding: 20px 40px;
  position: relative;
}
.ecran_liste_campagnes .right_content_main .data {
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow-x: auto;
  margin-top: 30px;
  position: relative;
  width: 100%;
  overflow-y: hidden;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media (min-width: 1900px) {
  .ecran_liste_campagnes .right_content_main .data {
    max-width: 1440px;
  }
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar {
  height: 15px;
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar-track {
  background: lightgrey;
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar-thumb {
  background: rgba(0, 102, 255, 0.8);
  border-radius: 10px;
}
.ecran_liste_campagnes .right_content_main .data::-webkit-scrollbar-thumb:hover {
  background: #0066ff;
}
.ecran_liste_campagnes .right_content_main .data table {
  border-collapse: collapse;
  border: none;
  position: relative;
  table-layout: auto;
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
}
.ecran_liste_campagnes .right_content_main .data table .meta header {
  width: 100%;
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  padding: 15px;
  padding-left: 35px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .right {
  position: sticky;
  right: 20px;
  top: 0;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .right i {
  color: #e83e8c;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .left {
  display: flex;
  align-items: center;
  position: sticky;
  left: 20px;
  top: 0;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .left .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main .data table .meta header .left img {
  margin-right: 20px;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr td.first_column, .ecran_liste_campagnes .right_content_main .data table tbody tr td.second_column {
  position: sticky;
  top: 0;
  z-index: 99;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr td.first_column {
  left: -1px;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr td.second_column {
  left: 50px;
  border-right: 0.5px solid rgba(0, 0, 0, 0.13) !important;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr:not(.brouillon):nth-child(even) td {
  background: #e5eaf1;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr:not(.brouillon):nth-child(odd) td {
  background: #fff;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.troisieme_cas td {
  background-color: rgba(232, 62, 140, 0.7);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne {
  border-bottom: 1px solid rgba(238, 238, 238, 0.4);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne:hover td {
  background-color: #e5eaf1;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne td {
  border: 0.5px solid rgba(0, 0, 0, 0.13);
  padding: 10px 0;
  transition: all ease 0.3s;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.campagne .brouillon {
  display: block;
  text-align: right;
  margin: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon {
  background-color: #f9f9f9;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon.troisieme_cas td.first_column, .ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon.troisieme_cas td.second_column {
  background-color: rgba(232, 62, 140, 0.7);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td {
  padding: 10px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(41, 44, 85, 0.69);
  border: 0.5px solid rgba(0, 0, 0, 0.13);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.first_column, .ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.second_column {
  background-color: #f9f9f9;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.13);
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.second_column {
  border-right: 1px solid #f9f9f9;
  left: 20px !important;
}
.ecran_liste_campagnes .right_content_main .data table tbody tr.brouillon td.first {
  background-color: #f9f9f9;
  padding-left: 10px;
  border-left: none;
  padding-right: 10px;
  min-width: 400px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td {
  padding: 10px;
  text-align: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  color: #000000;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .Mui-checked {
  color: #0066ff;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .MuiSwitch-track {
  background-color: #0066ff;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .cree_par_img {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  padding-left: 10px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .actions i {
  margin-left: 20px;
  cursor: pointer;
  font-size: 20px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .actions img {
  margin: 0 7px;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_main .data table tbody td.first {
  text-align: left;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  color: #000000;
}
.ecran_liste_campagnes .right_content_main .data table tbody td.first p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_not_ok {
  position: relative;
  border: 2px solid #e70d0d;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_not_ok:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e70d0d;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_not_ok_2 {
  position: relative;
  border: 2px solid #e70d0d;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok {
  position: relative;
  border: 2px solid #4cc937;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4cc937;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok_2 {
  position: relative;
  border: 2px solid #4cc937;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_med {
  position: relative;
  border: 2px solid orange;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_med:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: orange;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
}
.ecran_liste_campagnes .right_content_main .data table tbody td .status_ok_2 {
  position: relative;
  border: 2px solid orange;
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
}
.ecran_liste_campagnes .right_content_main .data table thead {
  border-radius: 10px 10px 0px 0px;
}
.ecran_liste_campagnes .right_content_main .data table thead th.first_status, .ecran_liste_campagnes .right_content_main .data table thead th.name_campaign, .ecran_liste_campagnes .right_content_main .data table thead th.last {
  position: sticky;
  top: 0;
  z-index: 888;
}
.ecran_liste_campagnes .right_content_main .data table thead th.first_status {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.13) !important;
}
.ecran_liste_campagnes .right_content_main .data table thead th.last {
  right: 0;
}
.ecran_liste_campagnes .right_content_main .data table thead th.name_campaign {
  left: -1px;
}
.ecran_liste_campagnes .right_content_main .data table thead .df {
  display: flex;
  align-items: center;
}
.ecran_liste_campagnes .right_content_main .data table thead .dynamic_columns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.ecran_liste_campagnes .right_content_main .data table thead .couts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ecran_liste_campagnes .right_content_main .data table thead .couts p {
  font-size: 12px;
}
.ecran_liste_campagnes .right_content_main .data table thead .couts input {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
}
.ecran_liste_campagnes .right_content_main .data table thead th {
  background-color: #fff;
  z-index: 99;
  text-align: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-collapse: collapse;
  position: sticky;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main .data table thead th.first {
  text-align: left;
}
.ecran_liste_campagnes .right_content_main .data table thead th.last {
  padding-right: 20px;
}
.ecran_liste_campagnes .right_content_main .informations_compte {
  background-color: #fff;
  margin-top: 50px;
  border-radius: 0 0 5px 5px;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content {
  padding: 20px 30px;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 25px;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul li {
  margin: 15px 0;
  position: relative;
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul li:before {
  content: "";
  background-color: #e83e8c;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.ecran_liste_campagnes .right_content_main .informations_compte .content ul li p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_liste_campagnes .right_content_main .informations_compte header {
  padding: 20px 30px;
  background: rgba(41, 44, 85, 0.15);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_liste_campagnes .right_content_main .informations_compte header .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}/*# sourceMappingURL=style.css.map */