.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader .overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader .overlay .content {
  padding: 0px 50px 20px 50px;
  background-color: #fff;
  border-radius: 10px;
}

.center {
  display: flex;
  justify-content: center;
  margin: 20px 0 40px 0;
  position: relative;
  left: -5px;
}

.loader .overlay .content h3 {
  font-size: 24px;
}
