@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.screen_creation_package_centre_interet {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_creation_package_centre_interet .right_content {
  transition: all ease 0.3s;
}
.screen_creation_package_centre_interet .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_creation_package_centre_interet .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_creation_package_centre_interet .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_creation_package_centre_interet .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_creation_package_centre_interet .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_centre_interet .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_centre_interet .right_content_main {
  padding: 20px 40px;
}
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .client,
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .client_title,
.screen_creation_package_centre_interet .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_centre_interet .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.screen_creation_package_centre_interet .right_content_main .sections .section.section_centre_interet .input_group_autocomplete input {
  border-bottom: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .mode_disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(167, 167, 167, 0.5);
  z-index: 99;
  cursor: not-allowed;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_currency {
  margin-left: 5px;
  padding-left: 5px;
  border-left: 0.5px solid rgba(0, 0, 0, 0.4) !important;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .container_currency_input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .container_currency_input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px;
  margin-right: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border-bottom: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group select {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group .css-oarg8b-MuiAutocomplete-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group input {
  width: 100%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group input,
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group input option,
.screen_creation_package_centre_interet .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .inputError {
  border-bottom: 1px solid red;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .messageError {
  font-size: 12px;
  color: red;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content {
  padding: 20px;
  background-color: #fff;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add {
  margin-top: 20px;
  padding-top: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .add .fe button i {
  margin-right: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 5px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-2m9kme-MuiFormControl-root,
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root,
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .left > * {
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .right {
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .right .row {
  margin-bottom: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_creation_package_centre_interet .right_content_main .sections .section .informations_generales_content .row > * {
  width: 45%;
}
.screen_creation_package_centre_interet .right_content_main .sections .section:first-child {
  margin: 0;
}
.screen_creation_package_centre_interet .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.screen_creation_package_centre_interet .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_creation_package_centre_interet .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.screen_creation_package_centre_interet .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_centre_interet .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */