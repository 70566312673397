@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
.screen_creation_campagne {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_creation_campagne .right_content {
  transition: all ease 0.3s;
}
.screen_creation_campagne .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_creation_campagne .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_creation_campagne .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_creation_campagne .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_campagne .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_campagne .right_content_main {
  padding: 20px 40px;
}
.screen_creation_campagne .right_content_main .choix_compte_publicitaire {
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.screen_creation_campagne .right_content_main .choix_compte_publicitaire p {
  margin-right: 20px;
  color: #000;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}
.screen_creation_campagne .right_content_main .choix_compte_publicitaire select {
  padding: 5px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.screen_creation_campagne .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.screen_creation_campagne .right_content_main .client_advanced_mode .client,
.screen_creation_campagne .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_main .client_advanced_mode .client_title,
.screen_creation_campagne .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_campagne .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.screen_creation_campagne .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;
  /* or 160% */
  padding: 5px 20px;
  margin: 1rem;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content button i {
  margin-right: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .sticky-button {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  bottom: 10px; /* Adjust based on your layout */
  z-index: 100; /* Ensure it is above other content */
  border: 1px solid #ccc; /* Optional: for better visibility */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 0px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .budget_adset .number {
  border: none;
  min-width: 60px;
  outline: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .nbr_adets,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .budget_adset,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .currency {
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .number {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 1px 2px rgba(232, 62, 140, 0.27);
  border-radius: 2px;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  outline: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .number.number_1 {
  max-width: 60px;
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .number.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: left;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .length {
  margin: 3px;
  align-self: flex-start;
  background: rgba(252, 232, 241, 0.397);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 7px 7px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #292c55;
  width: 40px;
  display: flex;
  justify-content: center;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage {
  margin: 3px;
  align-self: flex-start;
  background-color: #f0f0f0;
  padding: 6px 6px;
  border-radius: 18px;
  border: 1px solid #e83e8c;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage i {
  font-size: 14px;
  cursor: pointer;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage .truncate {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage:hover .truncate {
  white-space: normal; /* Show full text on hover */
  overflow: visible; /* Show full text on hover */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage p,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage i {
  color: #292c55;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .ciblage p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* or 200% */
  letter-spacing: 0.15px;
  margin-right: 10px;
  word-break: break-word;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .MuiAutocomplete-root {
  flex: 1;
  min-width: 75%; /* Adjust as needed */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .ciblages .MuiFormControl-root {
  width: 100%; /* Ensures the TextField takes up the available width */
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: #292c55;
  word-break: break-all;
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .title .circle {
  width: 8px;
  height: 8px;
  left: 346px;
  top: 828px;
  background: #e83e8c;
  display: inline-block;
  margin-right: 20px;
  border-radius: 50%;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .dates-adset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package .dates-adset .form-control {
  font-size: 14px; /* Adjust the font size as needed */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  color: #292c55;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .single_package select {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
  width: 60px;
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .expand-button {
  margin-top: 12px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px; /* Adjust height as needed */
  width: calc(100% + 40px);
  cursor: pointer;
  background-color: rgba(252, 232, 241, 0.397);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_content .single_package_container .expand-button:hover {
  background-color: rgba(248, 200, 222, 0.603);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_header {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_header p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.15px;
  color: rgba(41, 44, 85, 0.7);
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille_header select {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 42px;
  width: 70px;
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .ads_detaille .ads_detaille_header,
.screen_creation_campagne .right_content_main .sections .section .ads_detaille .ads_detaille_content .single_package {
  display: grid;
  grid-template-columns: 3fr 70px 4fr 4fr 4fr 200px 95px 20px;
  column-gap: 1rem;
}
.screen_creation_campagne .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.screen_creation_campagne .right_content_main .sections .section .input_currency {
  margin-left: 5px;
  padding-left: 5px;
  border-left: 0.5px solid rgba(0, 0, 0, 0.4) !important;
}
.screen_creation_campagne .right_content_main .sections .section .container_currency_input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiSelect-select,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiSelect-outlined,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiInputBase-input,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .MuiOutlinedInput-input,
.screen_creation_campagne .right_content_main .sections .section .container_currency_input .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 10px;
  margin-right: 13px;
  font-size: 15px;
}
.screen_creation_campagne .right_content_main .sections .section .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #aaa !important;
}
.screen_creation_campagne .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .rdt {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .input_group .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .MuiInputBase-input,
.screen_creation_campagne .right_content_main .sections .section .input_group .MuiOutlinedInput-input,
.screen_creation_campagne .right_content_main .sections .section .input_group .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border-bottom: none;
}
.screen_creation_campagne .right_content_main .sections .section .input_group select {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.screen_creation_campagne .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_campagne .right_content_main .sections .section .input_group .css-oarg8b-MuiAutocomplete-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.screen_creation_campagne .right_content_main .sections .section .input_group input {
  width: 100%;
  font-size: 16px;
}
.screen_creation_campagne .right_content_main .sections .section .input_group input,
.screen_creation_campagne .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.screen_creation_campagne .right_content_main .sections .section .input_group input option,
.screen_creation_campagne .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content {
  position: relative;
  padding: 20px;
  background-color: #fff;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .mode_disabled_no_cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(122, 122, 122, 0.1);
  z-index: 1;
  cursor: not-allowed;
  /* Change the color of input fields when disabled mode is active */
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add {
  margin-top: 20px;
  padding-top: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .add .fe button i {
  margin-right: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiInputBase-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiOutlinedInput-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 5px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-2m9kme-MuiFormControl-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiAutocomplete-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-fullWidth,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiTextField-root,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-1qqsdnr-MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiAutocomplete-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-fullWidth .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiFormControl-fullWidth .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline,
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .css-wb57ya-MuiFormControl-root-MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .left > * {
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .right {
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .right .row {
  margin-bottom: 0;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .row > * {
  width: 45%;
}
.screen_creation_campagne .right_content_main .sections .section .informations_generales_content .mode_disabled_no_cursor + input {
  background-color: #f4f4f4; /* Example disabled color */
}
.screen_creation_campagne .right_content_main .sections .section:first-child {
  margin: 0;
}
.screen_creation_campagne .right_content_main .sections .section_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.screen_creation_campagne .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_creation_campagne .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.screen_creation_campagne .right_content_main .sections .section_header .mode_disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(167, 167, 167, 0.5);
  z-index: 1;
  pointer-events: None;
}
.screen_creation_campagne .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_campagne .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
  cursor: pointer;
}

.inputError {
  border-bottom: 1px solid red;
}

.messageError {
  font-size: 12px;
  color: red;
}

.blockError {
  border: 1px solid red;
}

.warningMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  font-weight: bold;
  background: rgb(255, 247, 231);
  border: 2px solid #ffa500;
  border-radius: 10px;
}
.warningMsg p {
  color: #ffa500;
}
.warningMsg .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 6rem;
  margin: 0.5rem 0;
}
.warningMsg .btn span {
  color: #ffa500;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*# sourceMappingURL=style.css.map */
