@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.ecran_campagne_configurer_strategie_2 {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_campagne_configurer_strategie_2 .right_content {
  transition: all ease 0.3s;
}
.ecran_campagne_configurer_strategie_2 .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_campagne_configurer_strategie_2 .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main {
  padding: 20px 40px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps .step.active p {
  opacity: 1;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .steps > * {
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  flex: 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead th {
  text-align: left;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .historique_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content {
  display: flex;
  justify-content: space-between;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events {
  flex: 1;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .right button {
  background: #e83e8c;
  border-radius: 86px;
  padding: 5px 30px;
  border: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 218% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left {
  display: flex;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left .css-gcwvw8-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events .searh_add .left i {
  color: #e83e8c;
  font-size: 20px;
  margin-right: 20px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th {
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .fa-redo-alt,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
  color: #4cc937;
  padding-right: 30px;
}
@media (max-width: 1366px) {
  .ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green,
  .ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green {
    width: 300px;
  }
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td.color_green .css-187mznn-MuiSlider-root,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th.color_green .css-187mznn-MuiSlider-root {
  color: #e83e8c;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table td .circle,
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .events table thead th {
  text-align: left;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie {
  width: 260px;
  background: #fff6fa;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie p {
  text-align: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie .round {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #e83e8c;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_content .choix_strategie_title {
  margin: 20px 0;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_campagne_configurer_strategie_2 .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}/*# sourceMappingURL=style.css.map */