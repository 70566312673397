@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
.ecran_creation_campagne_confirmation {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_creation_campagne_confirmation .right_content {
  transition: all ease 0.3s;
}
.ecran_creation_campagne_confirmation .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_creation_campagne_confirmation .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_creation_campagne_confirmation .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_creation_campagne_confirmation .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_main {
  padding: 20px 40px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie {
  margin-top: 30px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique {
  width: 80%; /*was 90% in updatecreas*/
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container {
  position: relative;
  cursor: pointer;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container:hover .infobulle {
  opacity: 1;
  visibility: visible;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container .infobulle {
  position: absolute;
  background-color: #e83e8c;
  padding: 10px;
  border-radius: 4px;
  width: 300px;
  top: 150%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container .infobulle:after {
  content: "";
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #e83e8c;
  border-left: 5px solid transparent;
  position: absolute;
  bottom: 100%;
  left: 15px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .infobulle_img_container .infobulle p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #fff;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances {
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(232, 62, 140, 0.25);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td span.round {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e83e8c;
  margin-right: 30px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second {
  padding: 10px;
  margin: 100px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .wrapper {
  background: #fff4f9;
  padding: 1px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .wrapper .header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px;
  margin-bottom: 5px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .wrapper .header h3 {
  color: #e83e63;
  font-size: 17px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  margin: 0px;
  padding: 0px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne .number_see .number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  /* or 240% */
  letter-spacing: 0.15px;
  color: #000;
  margin-right: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne .number_see i {
  color: #e83e8c;
  font-size: 20px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table .second .td_content .ligne p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  text-align: center;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table thead {
  background: rgba(244, 23, 125, 0.04);
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th {
  padding: 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .fa-redo-alt,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .fa-redo-alt {
  color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td.color_green,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th.color_green {
  color: #4cc937;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table td .circle,
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table th .circle {
  display: inline-block;
  margin-right: 20px;
  width: 8px;
  height: 8px;
  background-color: #e83e8c;
  border-radius: 4px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances table tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  border-collapse: collapse;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring {
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  padding-bottom: 30px;
  padding-top: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .ad {
  margin-right: 20px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .monitor, .ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .adset {
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .monitor p, .ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .adset p {
  margin-right: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .monitor input[type=checkbox], .ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .performances .monitoring .adset input[type=checkbox] {
  width: 20px;
  height: 20px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe button {
  padding: 5px 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0px 10px;
  border: none;
  /* or 133% */
  cursor: pointer;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe button.update {
  background-color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .performances_historique .dfe button.save {
  background-color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row button {
  padding: 5px 20px;
  border: 1px solid #e83e8c;
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row .left {
  display: flex;
  align-items: center;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion .row .left img {
  margin-left: 10px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .performance_objectif_conversion_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  color: #292c55;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser {
  margin-bottom: 40px;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group .css-574lq2-MuiFormControl-root {
  margin-top: 0;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser .input_group label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
  display: block;
}
.ecran_creation_campagne_confirmation .right_content_main .configurer_strategie_content .evenements_a_optimiser_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 30px;
}

.toggle_span {
  text-align: right;
}

.listing_item_campaign_content {
  background: #fff4f9;
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-template-rows: repeat(1fr);
  padding: 5px 0;
  gap: 0.5rem;
}
@media (max-width: 1366px) {
  .listing_item_campaign_content {
    padding: 5px 5px;
    gap: 1rem;
  }
}

/*# sourceMappingURL=style.css.map */
