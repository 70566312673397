.left_menu {
  background-color: #292c55;
  width: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  transition: all ease 0.3s;
}

.left_menu .logo_seperator {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px; /* Keeps logo near top */
}

.left_menu .logo_seperator img {
  width: 100%;
  max-width: 200px;
  object-fit: contain;
  margin-bottom: 10px; /* Reduced spacing between logo and separator */
}

.left_menu .separator {
  opacity: 0.5;
  border: 0.5px solid #ffffff;
  width: 100%;
  margin-top: 5px; /* Slight spacing above separator */
}

.left_menu .menu {
  list-style: none;
  width: 80%;
  margin-top: 40px;
  margin-bottom: 60px;
}

.left_menu .expand_menu {
  position: absolute;
  top: 50%;
  right: -24px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  background-color: #000;
  z-index: 9999;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.left_menu .toggle_menu {
  position: absolute;
  top: 50%;
  right: -17px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%);
  background-color: #000;
  z-index: 9999;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.left_menu .menu .menu_item {
  display: flex;
  align-items: center;
  margin: 25px 0;
  border-radius: 6px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.left_menu .menu .menu_item:hover, .left_menu .menu .menu_item.active {
  background-color: #fff;
}
.left_menu .menu .menu_item:hover p, .left_menu .menu .menu_item.active p {
  color: #000;
}
.left_menu .menu .menu_item p {
  margin-left: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #ffffff;
}
.left_menu .profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  cursor: pointer;
}
.left_menu .profile i {
  color: #fff;
}
.left_menu .profile .img_title {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.left_menu .profile .img_title p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.15px;
  color: #ffffff;
}
.left_menu .profile .img_title img {
  margin-right: 10px;
  width: 40px;
  border-radius: 50%;
}/*# sourceMappingURL=style.css.map */