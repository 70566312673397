.filter-input-wrapper {
  width: calc(100% - 2rem);
  margin: 0.5rem 1rem;
}
.filter-input-wrapper .filter-input {
  width: 100%;
  margin-bottom: 1rem;
}

.account-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 1rem;
}
.account-items .account-item {
  display: flex;
  align-items: center;
  padding: 0.3rem;
}
.account-items .account-item:hover {
  background: #eee;
}
.account-items .account-item i {
  color: gray;
  font-size: 28px;
  margin-right: 1rem;
}/*# sourceMappingURL=style.css.map */