.popup_session_expired_facebook {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.popup_session_expired_facebook .content {
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
}
.popup_session_expired_facebook .content h2,
.popup_session_expired_facebook .content h3,
.popup_session_expired_facebook .content h4 {
  text-align: center;
}
.popup_session_expired_facebook .content h3 {
  margin: 20px 0;
}
.popup_session_expired_facebook .content h4 {
  margin-bottom: 10px;
}
.popup_session_expired_facebook .content .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup_session_expired_facebook .content .buttons button {
  font-size: 17px;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.popup_session_expired_facebook .content .buttons button.success {
  background-color: #3498db;
}
.popup_session_expired_facebook .content .buttons button.danger {
  background-color: #e74c3c;
}/*# sourceMappingURL=style.css.map */