.campaign-element {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 2.5rem;
  border-radius: 0;
}
.campaign-element .wrapper {
  width: 76%;
}
.campaign-element .wrapper .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff4f9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
}
.campaign-element .wrapper .content {
  display: flex;
  justify-content: center;
  height: 76vh;
  overflow-y: scroll;
  background-color: #fff;
  padding: 20px;
  border-radius: 0;
}
.campaign-element .wrapper .content .ligne-elem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaign-element .wrapper .content tbody tr:nth-child(even) td {
  background: #eef0f4;
  padding: 0 2rem;
}
.campaign-element .wrapper .content tbody tr:nth-child(odd) td {
  background: #fff;
  padding: 0 2rem;
}
.campaign-element .wrapper .content tbody input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  outline: none;
  margin-left: 1rem;
}
.campaign-element .wrapper .content tbody button {
  background: transparent;
  border: none;
  color: #e83e8c;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}
.campaign-element .wrapper .content tbody button i {
  margin: 0 10px;
}
.campaign-element .wrapper .content tbody button i:hover {
  color: #e83e8c;
}
.campaign-element .wrapper .footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 1rem;
}
.campaign-element .wrapper .footer button {
  padding: 5px 20px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 144px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0px 10px;
  border: none;
  /* or 133% */
  cursor: pointer;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.campaign-element .wrapper .footer button.update {
  background-color: #e83e8c;
}
.campaign-element .wrapper .footer button.save {
  background-color: #e83e8c;
}/*# sourceMappingURL=style.css.map */