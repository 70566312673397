.message_not_connected_fb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.message_not_connected_fb .content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.message_not_connected_fb .content h2 {
  text-align: center;
  margin-top: 20px;
}
.message_not_connected_fb .content h2 a {
  color: #000;
}/*# sourceMappingURL=style.css.map */