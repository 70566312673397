@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.reports,
.products {
  display: flex;
  /* height: 90vh; */
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
/* form {
  font-size: 16px;
  font-size: max(16px, 1em);
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: #fff;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  bottom: 2rem;
} */
.search-bar-dropdown {
  width: 30em;
  margin-bottom: 1rem;
}

#results {
  position: absolute;
  width: inherit;
  overflow-y: scroll;
  height: 20em;
  /* border-bottom: 1px #eeeeee solid; */
}

#results > button {
  background-color: #fafafa;
}

p {
  color: rgb(116, 116, 116);
}
.aliases {
  margin: 1rem;
  min-width: min-content;
}
.DropSearch {
  text-align: center;
}

.mt {
  margin-top: 20px;
}

body {
  background-color: #f1f1f1;
}
