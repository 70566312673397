@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
.ecran_detail_campagne {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.ecran_detail_campagne .right_content {
  transition: all ease 0.3s;
}
.ecran_detail_campagne .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.ecran_detail_campagne .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.ecran_detail_campagne .right_content_header .create_campaign {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ecran_detail_campagne .right_content_header .create_campaign button {
  background: #e83e8c;
  box-shadow: 0px 5px 11px rgba(232, 62, 140, 0.25);
  border-radius: 79px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 10px 30px;
}
.ecran_detail_campagne .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ecran_detail_campagne .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main {
  padding: 20px 1.5%;
}
.ecran_detail_campagne .right_content_main .row {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.ecran_detail_campagne .right_content_main .row .column_1 {
  width: 27%;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px 0px 0px 5px;
  position: sticky;
  top: 145px;
  left: 0;
  max-height: 80vh;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar {
  width: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar-track {
  background: lightgrey;
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar-thumb {
  background: #e83e8c;
  border-radius: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1::-webkit-scrollbar-thumb:hover {
  background: violet;
}
.ecran_detail_campagne .right_content_main .row .column_1 .column_header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .column_header img {
  cursor: pointer;
}
.ecran_detail_campagne .right_content_main .row .column_1 .column_header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 116% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #292c55;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .meta_title {
  background: #fff4f9;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 133% */
  color: #292c55;
  position: sticky;
  top: 0px;
  z-index: 9;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .meta_title img {
  margin-right: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing {
  padding: 10px 15px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign {
  border: 1px solid #e83e8c;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign.selected {
  border: 2px solid #e83e8c;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign.selected .listing_item_title {
  background-color: #e83e8c;
  color: #fff;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign:last-child {
  margin-bottom: 0;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_title {
  background: #fff4f9;
  border-bottom: 1px solid #e83e8c;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 233% */
  color: #000000;
  text-align: center;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content {
  display: grid;
  grid-template-columns: repeat(2, 2fr) repeat(2, 1fr);
  gap: 0.2rem;
  padding: 10px 5px;
  align-items: end;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value .gr {
  color: #4cc937;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value .rd {
  color: #e74c3c;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 200% */
  color: #000000;
}
@media (max-width: 1366px) {
  .ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p {
    margin-right: 5px;
  }
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p .gr {
  color: #4cc937;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content .value p .rd {
  color: #e74c3c;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .listing_item_campaign_content > div h4 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #292c55;
  opacity: 0.75;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .item_campaign_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .item_campaign_actions_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2px;
  display: inline-block;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  background: rgba(232, 62, 140, 0.13);
  opacity: 0.5;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_1 .section .listing_item_campaign .item_campaign_actions_container img {
  margin: 0 5px;
  width: 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 {
  width: 71.5%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee {
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content {
  padding: 15px 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content .dfe {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content .dfe button {
  border: 1px solid #e83e8c;
  border-radius: 32px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 11.5px;
  /* identical to box height, or 243% */
  color: #292c55;
  padding: 5px 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content .dfe button i {
  color: #e83e8c;
  font-size: 20px;
  margin-left: 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 25px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li {
  margin: 15px 0;
  position: relative;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li:before {
  content: "";
  background-color: #e83e8c;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee .content ul li p a {
  text-decoration: underline;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee header {
  padding: 20px 30px;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .optimisation_realisee header .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites {
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .sorting-options {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .sorting-options .sorting-arrow {
  padding: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content {
  padding: 1.5% 1.5% 0;
  display: grid;
  grid-template-columns: repeat(3, 32%); /* Three columns with equal width */
  column-gap: 2%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign {
  padding-bottom: 5%; /* Set the percentage-based row gap */
  align-content: start;
  background: #ffffff;
  border-radius: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_title {
  background-color: #fff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  /* or 185% */
  display: flex;
  margin: 0px auto;
  letter-spacing: 0.15px;
  color: #292c55;
  max-width: 100%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_title h3 {
  max-width: 97%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .main_image {
  object-fit: contain;
  max-width: 100%;
  max-height: calc(var(--container-width) * 0.31);
  display: flex;
  margin: 0px auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_campaign_wrapper {
  padding: 2%;
  background: #fff4f9;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .toggle_span {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .toggle_span h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value {
  display: flex;
  align-items: center;
  align-content: flex-end;
  margin-bottom: 7px;
}
@media (max-width: 1366px) {
  .ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value {
    margin-top: 0;
  }
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* or 200% */
  text-align: center;
  align-content: flex-end;
  color: #000000;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .value p .gr {
  color: #4cc937;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign h4 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  word-wrap: break-word;
  color: #292c55;
  text-align: center;
  margin-bottom: 5px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_campaign_content {
  display: grid;
  grid-template-columns: 28% 28% 18% 18%;
  align-content: flex-end;
  column-gap: 3%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .listing_item_campaign_content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .additionnal_items {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  align-content: flex-end;
  column-gap: 3%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .additionnal_items > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .item_campaign_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .item_campaign_actions_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2px;
  display: inline-block;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  background: rgba(232, 62, 140, 0.13);
  opacity: 0.5;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .content .listing_item_campaign .item_campaign_actions_container img {
  margin: 0 5px;
  width: 15px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites header {
  padding: 20px 30px;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites header .title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .expand-button {
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px; /* Adjust height as needed */
  width: 100%;
  cursor: pointer;
  background-color: rgba(252, 232, 241, 0.397);
}
.ecran_detail_campagne .right_content_main .row .column_2 .top_publicites .expand-button:hover {
  background-color: rgba(248, 200, 222, 0.603);
}
.ecran_detail_campagne .right_content_main .row .column_2 .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets {
  width: 45%;
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
@media (max-width: 1366px) {
  .ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences,
  .ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets {
    width: 42%;
  }
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences h6,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets h6 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  padding: 20px;
  text-transform: uppercase;
  text-align: center;
  background: #ffffff;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul {
  padding: 20px;
  list-style: none;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .right,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .left,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .right,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .left {
  width: 50%;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .right,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .right {
  display: flex;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #292c55;
  padding-left: 20px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .right .chart,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .right .chart {
  margin-right: 5px;
  background-color: #e83e8c;
  height: 10px;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .left,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .left {
  display: flex;
  align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #000000;
  word-break: break-all;
}
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_audiences ul li .left span,
.ecran_detail_campagne .right_content_main .row .column_2 .row .top_interets ul li .left span {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #292c55;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 7px;
  line-height: 24px;
  /* identical to box height, or 343% */
  letter-spacing: 0.15px;
  color: #ffffff;
}

.select-border-red {
  background-color: #f1f1f1;
  border: 1px solid #e83e8c;
  border-radius: 5px;
  padding: 5px;
}
.select-border-red select,
.select-border-red option {
  border: none;
  background-color: transparent;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #292c55;
}
.select-border-red select {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("/down-arrow.png");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  background-size: 15px;
}

/*# sourceMappingURL=style.css.map */
