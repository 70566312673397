.modal_filter_insights_by_date {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal_filter_insights_by_date .content {
  border-radius: 20px;
  background-color: #fff;
  width: 400px;
  padding: 20px;
}
.modal_filter_insights_by_date .content h1 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
}
.modal_filter_insights_by_date .content .dates {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.modal_filter_insights_by_date .content .dates input {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.modal_filter_insights_by_date .content .dates h2 {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 5px;
}
.modal_filter_insights_by_date .content .dates .date_input {
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
}
.modal_filter_insights_by_date .content .buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.modal_filter_insights_by_date .content .buttons button {
  padding: 10px 20px;
  border-radius: 20px;
  color: #fff;
  margin: 0 10px;
  border: none;
}
.modal_filter_insights_by_date .content .buttons button.danger {
  background-color: #fff;
  color: #e83e8c;
  border: 1px solid #e83e8c;
}
.modal_filter_insights_by_date .content .buttons button.success {
  background-color: #e83e8c;
  color: #fff;
}/*# sourceMappingURL=style.css.map */