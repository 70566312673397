.screen_selectionner_publicites_existantes {
  @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_selectionner_publicites_existantes .right_content {
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_selectionner_publicites_existantes .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_header .back_title .back {
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main {
  padding: 20px 40px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section {
  background-color: #fff;
  padding: 30px 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .image_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .image_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  background-color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .image_title .title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .image_title .title {
  width: 80%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .image,
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item .video,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .image,
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item .video {
  position: relative;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item {
  position: relative;
  overflow: hidden;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item video {
  width: 100%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item:hover .video_item_actions {
  visibility: visible;
  opacity: 1;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions button:first-child {
  background-color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .video_item_actions button:last-child {
  background-color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item {
  position: relative;
  overflow: hidden;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item:hover .image_item_actions {
  visibility: visible;
  opacity: 1;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 99;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #fff;
  transition: all ease 0.3s;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions button:first-child {
  background-color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .image_item_actions button:last-child {
  background-color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .boxed {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__header {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__header p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__header i {
  color: #292c55;
  font-size: 25px;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content {
  padding: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .dfe {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .dfe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .dfe button.disabled {
  background-color: #ccc;
  color: #fff;
  cursor: not-allowed;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .empty_result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple {
  border: 1px solid transparent;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected {
  border: 1px solid #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .publicite_existante_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .publicite_existante_title {
  background-color: #e83e8c;
  color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .publicite_existante_title a,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .publicite_existante_title a {
  color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .screenshot_carousel header.entete_fcb,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .screenshot_carousel header.entete_fcb {
  background-color: #e83e8c !important;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel.selected .screenshot_carousel header.entete_fcb .entete_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple.selected .screenshot_carousel header.entete_fcb .entete_title {
  color: #fff !important;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
  width: 25%;
  background: #ffffff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .interactions p {
  display: flex;
  align-items: center;
  font-size: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 13px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .main_title_format {
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .main_title_format.title_format_story {
  margin-top: 50px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel {
    flex-direction: column;
    align-items: center;
  }
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel .title_slide_outside {
  margin-bottom: 5px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel {
    margin: 20px 0;
  }
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel .main_img {
  width: 100%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot {
  position: relative;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 3px;
  margin-bottom: 0;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb {
  padding: 8px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  outline: none;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected {
  background-color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected .entete_title,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected i {
  color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb.selected input {
  border-color: white;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons img,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .buttons i {
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot header.entete_fcb .entete_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left {
  display: flex;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .image img {
  width: 25px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text {
  margin-left: 5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h5,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right {
  display: flex;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right i {
  font-size: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right .fa-times {
  margin-left: 5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .title {
  margin-bottom: 5px;
  font-size: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .description {
  font-size: 8px;
  line-height: 1.4;
  margin-bottom: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper {
  padding-bottom: 10px;
  padding-right: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text {
  padding: 10px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -5px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text h5 {
  font-size: 10px;
  font-weight: 900;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text a,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .ressource_image_item .text button {
  background-color: #d8dadf;
  color: #000;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 5px;
  font-size: 7px;
  border: none;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  text-align: center;
  display: inline-block;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .swiper-slide {
  width: 80% !important;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .elements_supplementaires {
  position: absolute;
  bottom: 12%;
  right: 30px;
  color: #fff;
  font-size: 20px;
  text-shadow: 1px 1px #000;
  font-family: arial;
  display: none;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook .main_img {
  width: 100%;
  height: 160px;
  -o-object-fit: cover;
     object-fit: cover;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .liste_ressources_screenshot_facebook video {
  width: 100%;
  height: 160px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text {
  width: 50%;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_link {
  color: rgba(0, 0, 0, 0.4);
  font-size: 7px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_text .screenshot_footer_text_title {
  font-size: 8px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_carousel .screenshot_carousel.facebook_screenshot .screenshot_footer .screenshot_footer_cta button {
  border: none;
  padding: 5px;
  background-color: #ccc;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple {
  width: 20%;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding-bottom: 20px;
  cursor: pointer;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .image_container {
  display: flex;
  justify-content: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple img {
  width: auto;
  max-width: 100%;
  margin-top: 20px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .interactions {
  border-top: 1.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: space-around;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .interactions p {
  display: flex;
  align-items: center;
  font-size: 8px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .interactions p i {
  display: inline-block;
  margin-right: 3px;
  font-size: 8px;
  color: #333;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .publicite_existante_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* or 400% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  padding: 10px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .liste_pubs_existantes .apercu_simple .publicite_existante_title .link_preview {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* or 400% */
  letter-spacing: 0.15px;
  color: #aaa;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets {
  display: flex;
  align-items: center;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .comptes_ads {
  margin-right: 50px;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .comptes_ads select,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .adsets select {
  border: none;
  border-bottom: 1px solid #000;
  padding: 10px;
  min-width: 150px;
  background-color: #fff;
}
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .comptes_ads .MuiAutocomplete-root,
.screen_selectionner_publicites_existantes .right_content_main .main_section .main__content .comptes_ads_adsets .adsets .MuiAutocomplete-root {
  background-color: #fff;
}/*# sourceMappingURL=style.css.map */