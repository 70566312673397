@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.screen_creation_package_ciblage_demographique {
  display: flex;
  justify-content: flex-end;
  min-height: 100vh;
  background-color: #f1f1f1;
}
.screen_creation_package_ciblage_demographique .right_content {
  transition: all ease 0.3s;
}
.screen_creation_package_ciblage_demographique .right_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 2px solid #90dbf1;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #eee;
  z-index: 99;
}
.screen_creation_package_ciblage_demographique .right_content_header .back_title {
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_header .client {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.screen_creation_package_ciblage_demographique .right_content_header .client_title {
  margin: 0 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_ciblage_demographique .right_content_header_title {
  margin: 0 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  /* identical to box height, or 75% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_ciblage_demographique .right_content_main {
  padding: 20px 40px;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element {
  margin-bottom: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #e83e8c;
  border-radius: 25px;
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded {
  position: relative;
  overflow: hidden;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded > * {
  position: relative;
  z-index: 1;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded:after {
  background-color: #e5e5f7;
  opacity: 0.3;
  background: repeating-linear-gradient(45deg, #000, #000 2px, #e5e5f7 2px, #e5e5f7 10px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  content: "";
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element.excluded input {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element .unit {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* or 200% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 1px 2px rgba(232, 62, 140, 0.27);
  border-radius: 2px;
  width: 40px;
  height: 30px;
  outline: none;
  margin-right: 2px;
  padding-left: 5px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element:last-child {
  margin-right: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element_title {
  color: #e83e8c;
  margin-right: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* or 200% */
  letter-spacing: 0.15px;
}
.screen_creation_package_ciblage_demographique .right_content_main .selected_elements .element i {
  color: #e83e8c;
  font-size: 13px;
  margin-left: 20px;
}
.screen_creation_package_ciblage_demographique .right_content_main .title_zones_geo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .title_zones_geo i {
  cursor: pointer;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container {
  display: flex;
  justify-content: space-between;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input {
  width: 16%;
  margin: 0 15px;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input:first-child {
  margin: 0 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input:first-child input, .screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input:nth-child(2) input {
  background: rgba(232, 62, 140, 0.05);
  box-shadow: inset 0px 1px 2px rgba(232, 62, 140, 0.27);
  border-radius: 2px;
  border-bottom: none !important;
}
.screen_creation_package_ciblage_demographique .right_content_main .inputs_container .input.input_3 {
  width: 35%;
}
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .client,
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .advanced_mode {
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .client_title,
.screen_creation_package_ciblage_demographique .right_content_main .client_advanced_mode .advanced_mode_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections {
  margin-top: 30px;
  margin-bottom: 80px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section {
  background: #fff4f9;
  border-bottom: 0.5px solid rgba(232, 62, 140, 0.46);
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 30px 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .df {
  display: flex;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .label_age_genre {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input {
  width: 100%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.centre_interet {
  width: 100%;
  margin-bottom: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.centre_interet select {
  width: 100%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.objectif {
  width: 45%;
  margin-top: 20px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group.objectif select {
  width: 100%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select {
  border: none;
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input.css-1x51dt5-MuiInputBase-input-MuiInput-input, .screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  border-bottom: none;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group input option,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .input_group select option {
  padding: 10px;
  border-bottom: 1px solid green;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .inputError {
  border-bottom: 1px solid red;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .messageError {
  font-size: 12px;
  color: red;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content {
  padding: 20px;
  background-color: #fff;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add_audience .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add_audience .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add_audience .fe button i {
  margin-right: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.4);
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add .fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add .fe button {
  background: #e83e8c;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* or 160% */
  padding: 5px 20px;
  border: none;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .add .fe button i {
  margin-right: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .css-2m9kme-MuiFormControl-root,
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .css-1869usk-MuiFormControl-root {
  width: 100%;
  margin: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .left > * {
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .right {
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .right .row {
  margin-bottom: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .date_budget .right p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  color: #e83e8c;
  margin-bottom: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section .informations_generales_content .row > * {
  width: 45%;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section:first-child {
  margin: 0;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section_header i {
  color: #e83e8c;
}
.screen_creation_package_ciblage_demographique .right_content_main .sections .section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.screen_creation_package_ciblage_demographique .right_content_main .d-fe {
  display: flex;
  justify-content: flex-end;
}
.screen_creation_package_ciblage_demographique .right_content_main .d-fe button {
  padding: 10px 20px;
  border-radius: 25px;
  color: #fff;
  background-color: #e83e8c;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border: none;
  /* or 133% */
  letter-spacing: 0.15px;
  color: #ffffff;
}/*# sourceMappingURL=style.css.map */