.instagram_screenshot {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 250px;
  margin-bottom: 50px;
  cursor: pointer;
  padding-bottom: 10px;
}
.instagram_screenshot .main_img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
.instagram_screenshot .px {
  padding-left: 5px;
  padding-right: 5px;
}
.instagram_screenshot .main_video {
  width: 100%;
}
.instagram_screenshot header.entete_insta {
  padding: 3px 0;
  background-color: rgba(204, 204, 204, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}
.instagram_screenshot header.entete_insta.selected {
  background-color: #e83e8c;
  color: #fff;
}
.instagram_screenshot header.entete_insta.selected .title {
  color: #fff;
}
.instagram_screenshot header.entete_insta .title {
  font-family: "Rubik";
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}
.instagram_screenshot .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
}
.instagram_screenshot .account .left {
  display: flex;
  align-items: center;
}
.instagram_screenshot .account .left .image img {
  width: 25px;
}
.instagram_screenshot .account .left .text {
  margin-left: 5px;
}
.instagram_screenshot .account .left .text h5,
.instagram_screenshot .account .left .text h6 {
  font-weight: normal;
  font-size: 12px;
}
.instagram_screenshot .account .right {
  display: flex;
  width: auto !important;
}
.instagram_screenshot .account .right span {
  width: 4px;
  height: 4px;
  background-color: #000;
  display: inline-block;
  margin: 0 2px;
  border-radius: 50%;
}
.instagram_screenshot .learn_more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.instagram_screenshot .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
.instagram_screenshot .actions .right {
  width: auto !important;
}
.instagram_screenshot .actions .center {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.instagram_screenshot .actions .center span {
  width: 7px;
  height: 7px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  margin: 0 3px;
}
.instagram_screenshot .actions .center span.active {
  background-color: #3498db;
}
.instagram_screenshot .actions img {
  width: 15px;
  margin: 0 3px;
}
.instagram_screenshot .pxy {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}
.instagram_screenshot .views {
  font-size: 8px;
  margin-top: 10px;
  font-weight: normal;
}
.instagram_screenshot .title {
  font-size: 10px;
  margin: 5px 0;
  font-weight: normal;
  color: #000;
}
.instagram_screenshot .comments_date h6,
.instagram_screenshot .comments_date p {
  font-size: 8px;
  font-weight: normal;
}/*# sourceMappingURL=style.css.map */