@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.steps .step.active p {
  opacity: 1;
}
.steps .step a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from the parent element */
}
.steps .step a:hover, .steps .step a:visited, .steps .step a:active {
  color: inherit; /* Ensure the color remains consistent in all states */
  text-decoration: none; /* Ensure underline does not appear in any state */
}
.steps .step p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #292c55;
  opacity: 0.5;
}
.steps > * {
  margin-left: 5px;
  margin-right: 5px;
}

/*# sourceMappingURL=style.css.map */
