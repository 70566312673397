.alert_section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 99999; /* Ensure the alert is on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.alert_section .sub_section {
  box-shadow: 10px -1px 3px rgba(0, 0, 0, 0.2);
  background-color: #F8C8DE;
  border-radius: 10px;
  padding: 3px;
  left: 70px;
  position: relative;
}
.alert_section .sub_section_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.alert_section .sub_section_header i {
  color: #e83e8c;
}
.alert_section .sub_section_header_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 0.15px;
  color: #292c55;
  margin-right: 10px;
}
.alert_section .sub_section .alert_msg_config_adset {
  background-color: #fff;
  margin: 2px;
  padding: 10px;
}
.alert_section .sub_section .alert_msg_config_adset .content {
  background-color: #fff;
  padding: 5px;
  white-space: pre-line;
  text-align: center;
  margin: 10px 0px;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons button {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  border: none;
  outline: none;
  padding: 8px 15px;
  border-radius: 18px;
  background-color: #e83e8c;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons button.success {
  color: #FFF;
}
.alert_section .sub_section .alert_msg_config_adset .content .buttons button.danger {
  color: #40458A;
}

/*# sourceMappingURL=style.css.map */
