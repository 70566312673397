.search_asset {
  margin-bottom: 20px;
}
.search_asset input {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  border: none;
  min-width: 250px;
  padding: 5px;
  outline: none;
  border-bottom: 1px solid #e83e8c;
}
.search_asset input::-moz-placeholder {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.search_asset input::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}
.search_asset i {
  color: #e83e8c;
  margin-right: 5px;
}/*# sourceMappingURL=style.css.map */